import Countdown from 'components/atoms/Countdown/Countdown';
import LessonDetailsDialog from 'components/molecules/LessonDetailsDialog/LessonDetailsDialog';
import MediaErrorMessage from 'components/molecules/MediaErrorMessage/MediaErrorMessage';
import MediaSwitch from 'components/molecules/MediaSwitch/MediaSwitch';
import MembersAvatar from 'components/molecules/MembersAvatar/MembersAvatar';
import UserAvatar from 'components/molecules/UserAvatar/UserAvatar';
import Video from 'components/molecules/Video/Video';
import { HostMediaContext } from 'contexts/hostMediaContext';
import { LessonStatus } from 'models/lesson-status.enum';
import { ITutorLesson } from 'models/tutor-lesson';
import { IUser } from 'models/user';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from './TutorLessonLobby.module.scss';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  user: IUser;
  lesson: ITutorLesson;
  joinCallback: () => void;
  startCallback: () => void;
}

const TutorLessonLobby = ({ user, lesson, joinCallback, startCallback }: PropsWithChildren<ComponentProps>) => {
  const { startMicrophone, startCamera, camera, microphone, stopCamera, stopMicrophone, errorMicrophone, errorCamera } = useContext(HostMediaContext);
  const [ detailsVisibility, setDetailsVisibility ] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    startMicrophone();
    startCamera();
  }, []);

  const toggleDetailsHandler = useCallback(() => {
    setDetailsVisibility((visibility) => !visibility);
  }, [ detailsVisibility ]);

  const title = useMemo(() => {
    return t('Hello', { context: user.name.split(' ').shift() });
  }, [ user ]);

  const subTitle = useMemo(() => {
    return (<Countdown startTime={lesson.startTime} label={t("Lesson starts in")}/>);
  }, [ lesson ]);

  const footer = useMemo(() => {
    switch (lesson.status) {
      case LessonStatus.SCHEDULED:
      case LessonStatus.STARTED:
      case LessonStatus.FINISHED: {
        return (
          <>
            {
              lesson.capabilities.due && <Button onClick={startCallback} label={t("Start lesson")} icon="pi pi-play" className="w-full relative p-button-success">
                <div className="absolute right-2">
                  <MembersAvatar user={user} lesson={lesson}/>
                </div>
              </Button>
            }
            {
              lesson.capabilities.active && <Button onClick={joinCallback} label={t("Join a lesson")} icon="pi pi-chevron-right" className="w-full relative">
                <div className="absolute right-2">
                  <MembersAvatar user={user} lesson={lesson}/>
                </div>
              </Button>
            }
            <Button label={t("Show details")} icon="pi pi-info-circle" className="w-full p-button-outlined mt-3" onClick={toggleDetailsHandler}/>
          </>
        );
      }
      case LessonStatus.CANCELED: {
        return (
          <>
            <Message severity="warn" text={t("The lesson has been cancelled")} className="w-full"/>
            <Button label={t("Show details")} icon="pi pi-info-circle" className="w-full p-button-outlined mt-3" onClick={toggleDetailsHandler}/>
          </>
        );
      }
    }
  }, [ user, lesson ]);

  return (
    <>
      <div className={styles.wrapper}>
        <Card title={title} subTitle={subTitle} footer={footer} className={styles.card}>
          <div className={styles.media}>
            <Video track={camera} isMuted={!microphone} avatar={<UserAvatar user={user}/>}/>
          </div>
          <div className={styles.actions}>
            <MediaSwitch type="camera"
                         isEnabled={!!camera}
                         enable={startCamera}
                         disable={stopCamera}
            />
            <MediaSwitch type="microphone"
                         isEnabled={!!microphone}
                         enable={startMicrophone}
                         disable={stopMicrophone}
            />
          </div>
        </Card>
        <div className={styles.notifications}>
          <MediaErrorMessage context="microphone" error={errorMicrophone}/>
          <MediaErrorMessage context="camera" error={errorCamera}/>
        </div>
      </div>
      {detailsVisibility && <LessonDetailsDialog lesson={lesson} onHide={toggleDetailsHandler}/>}
    </>
  );
};

export default TutorLessonLobby;