import { useEffect, useState } from 'react';

const useDisplay = (): {
  track: MediaStreamTrack | null;
  start: () => void;
  stop: () => void;
} => {
  const [ trackPromise, setTrackPromise ] = useState<Promise<MediaStreamTrack | null> | null>(null);
  const [ track, setTrack ] = useState<MediaStreamTrack | null>(null);

  const start = () => {
    const trackPromise = navigator.mediaDevices.getDisplayMedia().then((stream: MediaStream) => {
      return stream.getVideoTracks()[0];
    }).catch((error: DOMException) => {
      console.error(error);

      return null;
    });

    setTrackPromise(trackPromise);
  };

  const stop = () => {
    setTrackPromise(null);
  };

  useEffect(() => {
    if (trackPromise !== null) {
      trackPromise.then((track) => {
        setTrack(track);

        if (track !== null) {
          track.addEventListener('ended', () => {
            setTrackPromise(null);
          });
        }
      });

      return () => {
        trackPromise.then((track) => {
          if (track !== null) {
            track.stop();
          }
        });
      };
    } else {
      setTrack(null);
    }
  }, [ trackPromise ]);

  return {
    track,
    start,
    stop,
  };
};

export default useDisplay;
