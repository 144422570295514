import { config } from 'config';
import { UserRole } from 'models/user-role';
import { Button } from 'primereact/button';
import { PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const LessonErrorMessage = ({ role, children }: PropsWithChildren<{ role: UserRole }>) => {
  const { t } = useTranslation();

  const clickHandler = useCallback(() => {
    switch (role) {
      case 'student': {
        window.open(`${config.appDomain}/dashboard/student/tutor/list`, '_self');
        break;
      }
      case 'tutor': {
        window.open(`${config.appDomain}/dashboard/tutor/student/list`, '_self');
        break;
      }
    }
  }, [ role ]);

  return (
    <div>
      <h1 className="font-semibold text-xl">{children}</h1>
      <Button className="mt-4 w-full" label={t("My lessons")} icon="pi pi-th-large" onClick={clickHandler}/>
    </div>
  );
};

export default LessonErrorMessage;