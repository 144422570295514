import AudioTrack from 'components/atoms/AudioTrack/AudioTrack';
import Username from 'components/atoms/Username/Username';
import TutorStudentAvatar from 'components/molecules/TutorStudentAvatar/TutorStudentAvatar';
import TutorStudentLink from 'components/molecules/TutorStudentLink/TutorStudentLink';
import UserAvatar from 'components/molecules/UserAvatar/UserAvatar';
import Video from 'components/molecules/Video/Video';
import { HostMediaContext } from 'contexts/hostMediaContext';
import { Peer } from 'hooks/usePeers';
import { IUser } from 'models/user';
import { PropsWithChildren, useContext } from 'react';
import styles from './TutorLessonMedia.module.scss';

interface ComponentProps {
  user: IUser;
  peer: Peer | null;
}

const TutorLessonMedia = ({ user, peer }: PropsWithChildren<ComponentProps>) => {
  const { display, camera, microphone, errorMicrophone } = useContext(HostMediaContext);

  return (
    <div className={styles.wrapper}>
      {
        !peer && (
          <div className={styles.hostOnly}>
            {
              display
                ? <Video track={display} isMuted={!microphone || !!errorMicrophone}/>
                : <Video track={camera} isMuted={!microphone || !!errorMicrophone} avatar={<UserAvatar user={user}/>}/>
            }
          </div>
        )
      }
      {
        !!peer && (
          <>
            <div className={styles.host}>
              {
                display
                  ? <Video track={display} isMuted={!microphone || !!errorMicrophone} username={<Username username={user.name}/>}/>
                  : <Video track={camera} isMuted={!microphone || !!errorMicrophone} username={<Username username={user.name}/>} avatar={<UserAvatar user={user}/>}/>
              }
            </div>
            <div className={styles.peer}>
              {
                peer.microphone && <AudioTrack track={peer.microphone}/>
              }
              {
                peer.display
                  ? <Video track={peer.display} isMuted={!peer.microphone} username={<TutorStudentLink studentId={peer.id}/>}/>
                  : <Video track={peer.camera} isMuted={!peer.microphone} username={<TutorStudentLink studentId={peer.id}/>} avatar={<TutorStudentAvatar studentId={peer.id}/>}/>
              }
            </div>
          </>
        )
      }
    </div>
  );
};

export default TutorLessonMedia;