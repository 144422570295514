import { memo, PropsWithChildren, useEffect, useState } from 'react';

interface ComponentProps {
  duration: number;
}

const Timer = ({ duration }: PropsWithChildren<ComponentProps>) => {
  const [ minutes, setMinutes ] = useState<number>(duration);
  const [ seconds, setSeconds ] = useState<number>(0);
  const [ durationInSeconds, setDurationInSeconds ] = useState<number>(duration * 60);

  useEffect(() => {
    const interval = setInterval(() => {
      if (durationInSeconds < 0) {
        clearInterval(interval);
      } else {
        setDurationInSeconds((duration) => duration - 1);
        setMinutes(Math.floor(durationInSeconds / 60));
        setSeconds(durationInSeconds % 60);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [ durationInSeconds ]);

  return (
    <span className={durationInSeconds < 0 ? 'text-red-500' : 'text-white'}>{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
  );
};

export default memo(Timer, (prevProps, nextProps) => {
  return prevProps.duration === nextProps.duration;
});