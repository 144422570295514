import { ITutorWebinar } from 'models/tutor-webinar';
import { useEffect, useMemo, useState } from 'react';
import redirectToAuth from 'utilities/redirectToAuth';
import useApi from './useApi';
import useAuthToken from './useAuthToken';
import { AxiosError } from 'axios';
import { BackendError } from 'models/backend-error';

const useTutorWebinar = (
  webinarId: string,
): ITutorWebinar | null => {
  const [ webinar, setWebinar ] = useState<ITutorWebinar | null>(null);

  const { getAuthToken, removeAuthToken } = useAuthToken();
  const authToken = useMemo<string | null>(() => getAuthToken() ?? null, [ getAuthToken ]);

  const api = useApi();

  useEffect(() => {
    if (authToken) {
      api.request<ITutorWebinar>({
        method: 'GET',
        url: `tutor/webinars/${webinarId}`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        setWebinar(data);
      }).catch((error: AxiosError<BackendError>) => {
        setWebinar(null);

        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  }, [ webinarId, authToken ]);

  return webinar;
};

export default useTutorWebinar;
