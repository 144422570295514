import LoadingSpinner from 'components/molecules/LoadingSpinner/LoadingSpinner';
import UnauthorizedSpinner from 'components/molecules/UnauthorizedSpinner/UnauthorizedSpinner';
import StudentLesson from 'components/pages/StudentLesson/StudentLesson';
import TutorLesson from 'components/pages/TutorLesson/TutorLesson';
import { HostMediaProvider } from 'contexts/hostMediaContext';
import useUser from 'hooks/useUser';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import StudentWebinar from './components/pages/StudentWebinar/StudentWebinar';
import TutorWebinar from './components/pages/TutorWebinar/TutorWebinar';

export function App() {
  const user = useUser();

  if (user) {
    return (
      <HostMediaProvider>
        <main className="min-h-screen bg-gray-200">
          <BrowserRouter>
            <Routes>
              <Route path="/l/:lessonId/tutor" element={<TutorLesson user={user}/>}/>
              <Route path="/l/:lessonId/student" element={<StudentLesson user={user}/>}/>
              <Route path="/w/:webinarId/tutor" element={<TutorWebinar user={user}/>}/>
              <Route path="/w/:webinarId/student" element={<StudentWebinar user={user}/>}/>
              <Route path="*" element={<UnauthorizedSpinner/>}/>
            </Routes>
          </BrowserRouter>
        </main>
      </HostMediaProvider>
    );
  } else {
    return (<LoadingSpinner/>);
  }
}