import { config } from 'config';

const useCookie = () => {
  const setCookie = (key: string, value: string, params?: [ string, string ][]): void => {
    let cookie = `${key}=${value}`;

    if (Array.isArray(params)) {
      [
        [ 'SameSite', 'Strict' ],
        [ 'Secure' ],
        [ 'domain', config.cookieDomain ],
        [ 'path', '/' ],
        ...params,
      ].forEach(([ paramKey, paramValue ]) => {
        cookie += `;${paramKey}=${paramValue}`;
      });
    } else {
      [
        [ 'SameSite', 'Strict' ],
        [ 'Secure' ],
        [ 'domain', config.cookieDomain ],
        [ 'path', '/' ],
      ].forEach(([ paramKey, paramValue ]) => {
        cookie += `;${paramKey}=${paramValue}`;
      });
    }

    document.cookie = cookie;
  };

  const getCookie = (key: string): string | undefined | null => {
    const value: string | undefined = document.cookie.split('; ').find((cookie: string) => cookie.startsWith(key))?.split('=')[1];

    if (value === 'undefined') {
      return undefined;
    }

    if (value === 'null') {
      return null;
    }

    return value;
  };

  const removeCookie = (key: string): void => {
    setCookie(key, '', [
      [ 'expires', 'Thu, 01 Jan 1970 00:00:01 GMT;' ],
    ]);
  };

  const hasCookie = (key: string): boolean => {
    return document.cookie.split(';').some((item) => item.trim().startsWith(`${key}=`));
  };

  return {
    setCookie,
    getCookie,
    removeCookie,
    hasCookie,
  };
};

export default useCookie;
