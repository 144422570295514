import { Peer } from 'hooks/usePeers';
import { useEffect, useState } from 'react';

const useTutorPeer = (peers: Peer[] | null): Peer | null => {
  const [ tutorPeer, setTutorPeer ] = useState<Peer | null>(null);

  useEffect(() => {
    if (peers !== null) {
      const tutorPeers = peers.filter((peer: Peer): boolean => peer.role === 'tutor');
      if (tutorPeers.length === 0) {
        setTutorPeer(null);
      } else if (tutorPeers.length === 1) {
        setTutorPeer(tutorPeers[0]);
      } else {
        throw new Error(`Expected at most one tutor connected, there are ${tutorPeers.length}`);
      }
    } else {
      setTutorPeer(null);
    }
  }, [ peers ]);

  return tutorPeer;
};

export default useTutorPeer;
