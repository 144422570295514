import { useCall } from '@ailogroup/salespoint-call-client';
import LoadingSpinner from 'components/molecules/LoadingSpinner/LoadingSpinner';
import MediaErrorToast from 'components/molecules/MediaErrorToast/MediaErrorToast';
import SignalerConflictDialog from 'components/molecules/SignalerConflictDialog/SignalerConflictDialog';
import SignalerErrorDialog from 'components/molecules/SignalerErrorDialog/SignalerErrorDialog';
import TutorLessonFooter from 'components/molecules/TutorLessonFooter/TutorLessonFooter';
import TutorLessonMedia from 'components/molecules/TutorLessonMedia/TutorLessonMedia';
import Chat from 'components/organisms/Chat/Chat';
import { config } from 'config';
import { HostMediaContext } from 'contexts/hostMediaContext';
import useCallUpstream from 'hooks/useCallUpstream';
import useChat from 'hooks/useChat';
import usePeers from 'hooks/usePeers';
import useSignaler, { SignalerState } from 'hooks/useSignaler';
import useSignalerPeers from 'hooks/useSignalerPeers';
import useSoundOnPeerChanges from 'hooks/useSoundOnPeerChanges';
import useStudentPeer from 'hooks/useStudentPeer';
import useTutorLessonConversationId from 'hooks/useTutorLessonConversationId';
import { ITutorLesson } from 'models/tutor-lesson';
import { IUser } from 'models/user';
import { PropsWithChildren, useContext, useMemo } from 'react';
import styles from './TutorLessonRoom.module.scss';

interface ComponentProps {
  user: IUser;
  lesson: ITutorLesson;
  enterKey: string;
  endCallback: () => void;
}

const TutorLessonRoom = ({ user, lesson, enterKey, endCallback }: PropsWithChildren<ComponentProps>) => {
  const { isChatOpened, toggleChat } = useChat();
  const { microphone, camera, display, errorMicrophone, errorCamera } = useContext(HostMediaContext);
  const { state: signalerState, signaler } = useSignaler(lesson.id, 'tutor', enterKey);
  const signalerPeers = useSignalerPeers(signaler);

  const callEnabled = useMemo<boolean>(() => signalerState === SignalerState.CONNECTED, [ signalerState ]);
  const { peerId: tutorCallId, peers: callPeers } = useCall({
    enabled: callEnabled,
    serverUrl: config.callServerUrl,
    roomId: lesson.id,
    microphone,
    camera,
    display,
    consumePreferences: null,
  });

  const conversationId = useTutorLessonConversationId({ studentId: lesson.student.id });

  useCallUpstream(signaler, tutorCallId);

  const peers = usePeers(signalerPeers, callPeers);
  const studentPeer = useStudentPeer(peers);

  useSoundOnPeerChanges(peers);

  switch (signalerState) {
    case SignalerState.CONFLICT: {
      return <SignalerConflictDialog/>;
    }
    case SignalerState.ERROR: {
      return <SignalerErrorDialog/>;
    }
    case SignalerState.CONNECTING: {
      return <LoadingSpinner/>;
    }
    case SignalerState.CONNECTED: {
      return (
        <div className="flex">
          <div className="bg-zinc-900 flex flex-col h-full justify-space-between min-h-screen relative w-full">
            <header className="h-6"/>
            <TutorLessonMedia user={user} peer={studentPeer}/>
            <TutorLessonFooter lesson={lesson} toggleChat={toggleChat} endCallback={endCallback} conversationId={conversationId}/>
          </div>

          {
            isChatOpened && conversationId && (
              <div className={styles.chat}>
                <Chat conversationId={conversationId} userRole="tutor"/>
              </div>
            )
          }

          <MediaErrorToast context="microphone" error={errorMicrophone}/>
          <MediaErrorToast context="camera" error={errorCamera}/>
        </div>
      );
    }
  }
};

export default TutorLessonRoom;