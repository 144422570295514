import { useEffect } from 'react';
import Signaler from 'signalers/Signaler';

const useCallUpstream = (
  signaler: Signaler | null,
  callId: string | null,
): void => {
  useEffect(() => {
    if (signaler !== null) {
      if (callId !== null) {
        signaler.callIn(callId);
      } else {
        signaler.hungUp();
      }
    }
  }, [ signaler, callId ]);
};

export default useCallUpstream;
