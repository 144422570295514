import { AxiosError } from 'axios';
import useApi from 'hooks/useApi';
import useAuthToken from 'hooks/useAuthToken';
import { BackendError } from 'models/backend-error';
import { IStudent } from 'models/student';
import { useEffect, useMemo, useState } from 'react';
import redirectToAuth from 'utilities/redirectToAuth';

const useTutorWebinarStudents = ({ webinarId }: { webinarId: string }): Pick<IStudent, 'id' | 'name'>[] => {
  const [ students, setStudents ] = useState<Pick<IStudent, 'id' | 'name'>[]>([]);

  const { getAuthToken, removeAuthToken } = useAuthToken();
  const authToken = useMemo<string | null>(() => getAuthToken() ?? null, [ getAuthToken ]);

  const api = useApi();

  useEffect(() => {
    if (authToken) {
      api.request<{ students: Pick<IStudent, 'id' | 'name'>[] }>({
        method: 'GET',
        url: `tutor/webinars/${webinarId}/students`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        setStudents(data.students);
      }).catch((error: AxiosError<BackendError>) => {
        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  }, [ webinarId ]);

  return students;
};

export default useTutorWebinarStudents;
