import { UserRole } from 'models/user-role';
import useApi from './useApi';
import useAuthToken from './useAuthToken';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BackendErrorType } from '../models/backend-error-type.enum';

export enum LessonClassroomStatus {
  LOADING = 'loading',
  UNAUTHENTICATED = 'unauthenticated',
  UNAUTHORIZED = 'unauthorized',
  LESSON_NOT_FOUND = 'lessonNotFound',
  LESSON_CANCELLED = 'lessonCancelled',
  LESSON_FINISHED = 'lessonFinished',
  LESSON_NOT_STARTED = 'lessonNotStarted',
  READY = 'ready',
}

interface ReturnType {
  status: LessonClassroomStatus,
  enterKey: string | null,
  refreshStatusFn: () => void,
}

const useLessonClassroom = (lessonId: string, role: UserRole): ReturnType => {
  const { getAuthToken } = useAuthToken();
  const authToken = useMemo<string | null>(() => getAuthToken() ?? null, [ getAuthToken ]);

  const [ status, setStatus ] = useState<LessonClassroomStatus>(LessonClassroomStatus.LOADING);
  const [ enterKey, setEnterKey ] = useState<string | null>(null);

  const api = useApi();

  useEffect(() => {
    if (authToken !== null) {
      api.request<{ key: string }>({
        method: 'POST',
        url: `${role}/lessons/${lessonId}/classroom/enter`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then((response) => {
        setEnterKey(response.data.key);
        setStatus(LessonClassroomStatus.READY);
      }).catch((error) => {
        if (error.response.data.type) {
          switch (error.response.data.type) {
            case BackendErrorType.AUTHENTICATION_REQUIRED:
            case BackendErrorType.AUTHENTICATION_ERROR: {
              setStatus(LessonClassroomStatus.UNAUTHENTICATED);
              return;
            }
            case BackendErrorType.USER_IS_NOT_A_STUDENT:
            case BackendErrorType.USER_IS_NOT_A_TUTOR: {
              setStatus(LessonClassroomStatus.UNAUTHORIZED);
              return;
            }
            case BackendErrorType.STUDENT_LESSON_NOT_FOUND:
            case BackendErrorType.TUTOR_LESSON_NOT_FOUND: {
              setStatus(LessonClassroomStatus.LESSON_NOT_FOUND);
              return;
            }
            case BackendErrorType.LESSON_NOT_STARTED: {
              setStatus(LessonClassroomStatus.LESSON_NOT_STARTED);
              return;
            }
            case BackendErrorType.LESSON_CANCELLED: {
              setStatus(LessonClassroomStatus.LESSON_CANCELLED);
              return;
            }
            case BackendErrorType.LESSON_FINISHED: {
              setStatus(LessonClassroomStatus.LESSON_FINISHED);
              return;
            }
          }
        }

        throw error;
      });
    } else {
      setStatus(LessonClassroomStatus.UNAUTHENTICATED);
    }
  }, [ lessonId, role, authToken ]);

  const refresh = useCallback(() => {
    if (authToken !== null) {
      api.request<{ key: string }>({
        method: 'POST',
        url: `${role}/lessons/${lessonId}/classroom/enter`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then((response) => {
        setEnterKey(response.data.key);
        setStatus(LessonClassroomStatus.READY);
      }).catch((error) => {
        if (error.response.data.type) {
          switch (error.response.data.type) {
            case BackendErrorType.AUTHENTICATION_REQUIRED:
            case BackendErrorType.AUTHENTICATION_ERROR: {
              setStatus(LessonClassroomStatus.UNAUTHENTICATED);
              return;
            }
            case BackendErrorType.USER_IS_NOT_A_STUDENT:
            case BackendErrorType.USER_IS_NOT_A_TUTOR: {
              setStatus(LessonClassroomStatus.UNAUTHORIZED);
              return;
            }
            case BackendErrorType.STUDENT_LESSON_NOT_FOUND:
            case BackendErrorType.TUTOR_LESSON_NOT_FOUND: {
              setStatus(LessonClassroomStatus.LESSON_NOT_FOUND);
              return;
            }
            case BackendErrorType.LESSON_NOT_STARTED: {
              setStatus(LessonClassroomStatus.LESSON_NOT_STARTED);
              return;
            }
            case BackendErrorType.LESSON_CANCELLED: {
              setStatus(LessonClassroomStatus.LESSON_CANCELLED);
              return;
            }
            case BackendErrorType.LESSON_FINISHED: {
              setStatus(LessonClassroomStatus.LESSON_FINISHED);
              return;
            }
          }
        }

        throw error;
      });
    } else {
      setStatus(LessonClassroomStatus.UNAUTHENTICATED);
    }
  }, [ lessonId, role, authToken ]);

  return { status, enterKey, refreshStatusFn: refresh };
};

export default useLessonClassroom;
