import Username from 'components/atoms/Username/Username';
import StudentStudentWebinarAvatar from 'components/molecules/StudentStudentWebinarAvatar/StudentStudentWebinarAvatar';
import StudentTutorWebinarAvatar from 'components/molecules/StudentTutorWebinarAvatar/StudentTutorWebinarAvatar';
import UserAvatar from 'components/molecules/UserAvatar/UserAvatar';
import Video from 'components/molecules/Video/Video';
import { HostMediaContext } from 'contexts/hostMediaContext';
import { Peer } from 'hooks/usePeers';
import useWebinarGrid from 'hooks/useWebinarGrid';
import { IStudent } from 'models/student';
import { IStudentWebinar } from 'models/student-webinar';
import { IUser } from 'models/user';
import { PropsWithChildren, useCallback, useContext } from 'react';
import styles from './StudentWebinarMedia.module.scss';

interface ComponentProps {
  user: IUser;
  webinar: IStudentWebinar;
  tutorPeer: Peer | null;
  studentPeers: Peer[];
  students: Pick<IStudent, 'id' | 'name'>[];
  pinnedPeerId: string | null;
  togglePinnedPeer: (peerId: string | null) => void;
}

const StudentWebinarMedia = ({ user, webinar, tutorPeer, studentPeers, students, pinnedPeerId, togglePinnedPeer }: PropsWithChildren<ComponentProps>) => {
  const { display, camera, microphone, errorMicrophone } = useContext(HostMediaContext);
  const { cols, rows } = useWebinarGrid(tutorPeer, studentPeers);

  const getStudentName = useCallback((studentId: string) => {
    return students.find((student) => student.id === studentId)?.name ?? '';
  }, [ students ]);

  return (
    <div className={styles.wrapper} data-pinned={pinnedPeerId !== null}>
      <div className={styles.pinned}>
        {
          pinnedPeerId === user.id && (
            <div onClick={() => togglePinnedPeer(user.id)} className="aspect-video md:aspect-auto bg-zinc-700 text-white flex items-center justify-center">
              {
                display
                  ? <Video track={display} isMuted={!microphone || !!errorMicrophone} username={<Username username={user.name}/>}/>
                  : <Video track={camera} isMuted={!microphone || !!errorMicrophone} username={<Username username={user.name}/>} avatar={<UserAvatar user={user}/>}/>
              }
            </div>
          )
        }
        {
          tutorPeer && tutorPeer.id === pinnedPeerId && (
            <div onClick={() => togglePinnedPeer(tutorPeer.id)} className="aspect-video md:aspect-auto bg-zinc-700 text-white flex items-center justify-center">
              {
                tutorPeer.display
                  ? <Video track={tutorPeer.display} isMuted={!tutorPeer.microphone} username={<Username username={webinar.tutor.name}/>}/>
                  : <Video track={tutorPeer.camera} isMuted={!tutorPeer.microphone} username={<Username username={webinar.tutor.name}/>} avatar={<StudentTutorWebinarAvatar webinarId={webinar.id} tutorName={webinar.tutor.name}/>}/>
              }
            </div>
          )
        }
        {
          studentPeers.filter((studentPeer) => studentPeer.id === pinnedPeerId).map((studentPeer) => {
            return (
              <div onClick={() => togglePinnedPeer(studentPeer.id)} key={studentPeer.id} className="aspect-video md:aspect-auto bg-zinc-700 text-white flex items-center justify-center">
                {
                  studentPeer.display
                    ? <Video track={studentPeer.display} isMuted={!studentPeer.microphone} username={<Username username={getStudentName(studentPeer.id)}/>}/>
                    : <Video track={studentPeer.camera} isMuted={!studentPeer.microphone} username={<Username username={getStudentName(studentPeer.id)}/>} avatar={<StudentStudentWebinarAvatar studentId={studentPeer.id} webinarId={webinar.id} studentName={getStudentName(studentPeer.id)}/>}/>
                }
              </div>
            );
          })
        }
      </div>
      <div className={styles.peers}>
        {
          !tutorPeer && studentPeers.length === 0 && (
            <div className="aspect-video md:aspect-auto bg-zinc-700 text-white flex items-center justify-center">
              {
                display
                  ? <Video track={display} isMuted={!microphone || !!errorMicrophone} username={<Username username={user.name}/>}/>
                  : <Video track={camera} isMuted={!microphone || !!errorMicrophone} username={<Username username={user.name}/>} avatar={<UserAvatar user={user}/>}/>
              }
            </div>
          )
        }
        {
          (tutorPeer || studentPeers.length > 0) && (
            <div data-cols={cols} data-rows={rows}>
              <div onClick={() => togglePinnedPeer(user.id)} className="aspect-video md:aspect-auto bg-zinc-700 text-white flex items-center justify-center">
                {
                  display
                    ? <Video track={display} isMuted={!microphone || !!errorMicrophone} username={<Username username={user.name}/>}/>
                    : <Video track={camera} isMuted={!microphone || !!errorMicrophone} username={<Username username={user.name}/>} avatar={<UserAvatar user={user}/>}/>
                }
              </div>
              {
                tutorPeer && (
                  <div onClick={() => togglePinnedPeer(tutorPeer.id)} className="aspect-video md:aspect-auto bg-zinc-700 text-white flex items-center justify-center">
                    {
                      tutorPeer.display
                        ? <Video track={tutorPeer.display} isMuted={!tutorPeer.microphone} username={<Username username={webinar.tutor.name}/>}/>
                        : <Video track={tutorPeer.camera} isMuted={!tutorPeer.microphone} username={<Username username={webinar.tutor.name}/>} avatar={<StudentTutorWebinarAvatar webinarId={webinar.id} tutorName={webinar.tutor.name}/>}/>
                    }
                  </div>
                )
              }
              {
                studentPeers.map((studentPeer) => {
                  return (
                    <div onClick={() => togglePinnedPeer(studentPeer.id)} key={studentPeer.id} className="aspect-video md:aspect-auto bg-zinc-700 text-white flex items-center justify-center">
                      {
                        studentPeer.display
                          ? <Video track={studentPeer.display} isMuted={!studentPeer.microphone} username={<Username username={getStudentName(studentPeer.id)}/>}/>
                          : <Video track={studentPeer.camera} isMuted={!studentPeer.microphone} username={<Username username={getStudentName(studentPeer.id)}/>} avatar={<StudentStudentWebinarAvatar studentId={studentPeer.id} webinarId={webinar.id} studentName={getStudentName(studentPeer.id)}/>}/>
                      }
                    </div>
                  );
                })
              }
            </div>
          )
        }
      </div>
      <div className={styles.list}>
        <div data-cols={1}>
          {
            pinnedPeerId !== user.id && (
              <div onClick={() => togglePinnedPeer(user.id)} className="aspect-video bg-zinc-700 text-white flex items-center justify-center">
                {
                  display
                    ? <Video track={display} isMuted={!microphone || !!errorMicrophone} username={<Username username={user.name}/>}/>
                    : <Video track={camera} isMuted={!microphone || !!errorMicrophone} username={<Username username={user.name}/>} avatar={<UserAvatar user={user}/>}/>
                }
              </div>
            )
          }
          {
            tutorPeer && tutorPeer.id !== pinnedPeerId &&
            <div onClick={() => togglePinnedPeer(tutorPeer.id)} className="aspect-video bg-zinc-700 text-white flex items-center justify-center">
              {
                tutorPeer.display
                  ? <Video track={tutorPeer.display} isMuted={!tutorPeer.microphone} username={<Username username={webinar.tutor.name}/>}/>
                  : <Video track={tutorPeer.camera} isMuted={!tutorPeer.microphone} username={<Username username={webinar.tutor.name}/>} avatar={<StudentTutorWebinarAvatar webinarId={webinar.id} tutorName={webinar.tutor.name}/>}/>
              }
            </div>
          }
          {
            studentPeers.filter((studentPeer) => studentPeer.id !== pinnedPeerId).map((studentPeer) => {
              return (
                <div onClick={() => togglePinnedPeer(studentPeer.id)} key={studentPeer.id} className="aspect-video bg-zinc-700 text-white flex items-center justify-center">
                  {
                    studentPeer.display
                      ? <Video track={studentPeer.display} isMuted={!studentPeer.microphone} username={<Username username={getStudentName(studentPeer.id)}/>}/>
                      : <Video track={studentPeer.camera} isMuted={!studentPeer.microphone} username={<Username username={getStudentName(studentPeer.id)}/>} avatar={<StudentStudentWebinarAvatar studentId={studentPeer.id} webinarId={webinar.id} studentName={getStudentName(studentPeer.id)}/>}/>
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default StudentWebinarMedia;