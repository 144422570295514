import Countdown from 'components/atoms/Countdown/Countdown';
import MediaErrorMessage from 'components/molecules/MediaErrorMessage/MediaErrorMessage';
import MediaSwitch from 'components/molecules/MediaSwitch/MediaSwitch';
import UserAvatar from 'components/molecules/UserAvatar/UserAvatar';
import Video from 'components/molecules/Video/Video';
import WebinarDetailsDialog from 'components/molecules/WebinarDetailsDialog/WebinarDetailsDialog';
import { HostMediaContext } from 'contexts/hostMediaContext';
import { ITutorWebinar } from 'models/tutor-webinar';
import { IUser } from 'models/user';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from './TutorWebinarLobby.module.scss';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  user: IUser;
  webinar: ITutorWebinar;
  joinCallback: () => void;
}

const TutorWebinarLobby = ({ user, webinar, joinCallback }: ComponentProps) => {
  const { startMicrophone, startCamera, camera, microphone, stopCamera, stopMicrophone, errorMicrophone, errorCamera } = useContext(HostMediaContext);
  const [ detailsVisibility, setDetailsVisibility ] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    startMicrophone();
    startCamera();
  }, []);

  const toggleDetailsHandler = useCallback(() => {
    setDetailsVisibility((visibility) => !visibility);
  }, [ detailsVisibility ]);

  const title = useMemo(() => {
    return t('Hello', { context: user.name.split(' ').shift() });
  }, [ user ]);

  const subTitle = useMemo(() => {
    return (<Countdown startTime={webinar.date.startTime} label={t("Webinar starts in")}/>);
  }, [ webinar ]);

  const footer = useMemo(() => {
    return (
      <>
        <Button onClick={joinCallback} label={t("Join a webinar")} icon="pi pi-chevron-right" className="w-full"/>
        <Button label={t("Show details")} icon="pi pi-info-circle" className="w-full p-button-outlined mt-3" onClick={toggleDetailsHandler}/>
      </>
    );
  }, [ user ]);

  return (
    <>
      <div className={styles.wrapper}>
        <Card title={title} subTitle={subTitle} footer={footer} className={styles.card}>
          <div className={styles.media}>
            <Video track={camera} isMuted={!microphone} avatar={<UserAvatar user={user}/>}/>
          </div>
          <div className={styles.actions}>
            <MediaSwitch type="camera"
                         isEnabled={!!camera}
                         enable={startCamera}
                         disable={stopCamera}
            />
            <MediaSwitch type="microphone"
                         isEnabled={!!microphone}
                         enable={startMicrophone}
                         disable={stopMicrophone}
            />
          </div>
        </Card>
        <div className={styles.notifications}>
          <MediaErrorMessage context="microphone" error={errorMicrophone}/>
          <MediaErrorMessage context="camera" error={errorCamera}/>
        </div>
      </div>
      {detailsVisibility && <WebinarDetailsDialog webinar={webinar} onHide={toggleDetailsHandler}/>}
    </>
  );
};

export default TutorWebinarLobby;