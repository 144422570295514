import { useEffect, useState } from 'react';

const useCamera = (): {
  track: MediaStreamTrack | null;
  error: DOMException | null;
  start: () => void;
  stop: () => void;
} => {
  const [ trackPromise, setTrackPromise ] = useState<Promise<MediaStreamTrack | null> | null>(null);
  const [ error, setError ] = useState<DOMException | null>(null);
  const [ track, setTrack ] = useState<MediaStreamTrack | null>(null);

  const start = () => {
    const trackPromise = navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: [ 'user' ],
        aspectRatio: 4 / 3,
      },
    }).then((stream) => {
      setError(null);

      return stream.getVideoTracks()[0];
    }).catch((exception: DOMException) => {
      setError(exception);

      return null;
    });

    setTrackPromise(trackPromise);
  };

  const stop = () => {
    setTrackPromise(null);
    setError(null);
  };

  useEffect(() => {
    if (trackPromise !== null) {
      trackPromise.then((track) => {
        setTrack(track);

        if (track !== null) {
          track.addEventListener('ended', () => {
            setTrackPromise(null);
          });
        }
      });

      return () => {
        trackPromise.then((track) => {
          if (track !== null) {
            track.stop();
          }
        });
      };
    } else {
      setTrack(null);
    }
  }, [ trackPromise ]);

  return {
    track,
    error,
    start,
    stop,
  };
};

export default useCamera;
