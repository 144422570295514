import Spinner from 'components/atoms/Spinner/Spinner';

const LoadingSpinner = () => {
  return (
    <div className="flex flex-col h-full min-h-screen justify-center items-center bg-gray-200 py-6">
      <Spinner size="2.5rem"/>
    </div>
  );
};

export default LoadingSpinner;