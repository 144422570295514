import { useCallback, useState } from 'react';

interface ReturnType {
  pinnedPeerId: string | null,
  togglePinnedPeer: (peer: string | null) => void,
}

const usePinnedPeer = (): ReturnType => {
  const [ pinnedPeerId, setPinnedPeerId ] = useState<string | null>(null);

  const togglePinnedPeer = useCallback((peerId: string | null) => {
    if (pinnedPeerId === peerId) {
      setPinnedPeerId(null);
    } else {
      setPinnedPeerId(peerId);
    }
  }, [ pinnedPeerId ]);

  return {
    pinnedPeerId,
    togglePinnedPeer,
  };
};

export default usePinnedPeer;
