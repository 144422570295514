import { PropsWithChildren, useEffect, useRef } from 'react';

interface ComponentProps {
  track: MediaStreamTrack;
}

const AudioTrack = ({ track }: PropsWithChildren<ComponentProps>) => {
  const ref = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.srcObject = new MediaStream([ track ]);
    }
  }, [ track ]);

  return (
    <audio ref={ref} autoPlay playsInline/>
  );
};

export default AudioTrack;