// https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia

export enum MediaErrorName {
  NOT_ALLOWED_ERROR = 'NotAllowedError',
  ABORT_ERROR = 'AbortError',
  NOT_FOUND_ERROR = 'NotFoundError',
  NOT_READABLE_ERROR = 'NotReadableError',
  OVERCONSTRAINED_ERROR = 'OverconstrainedError',
  SECURITY_ERROR = 'SecurityError',
  TYPE_ERROR = 'TypeError'
}
