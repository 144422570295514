import { io, Socket } from 'socket.io-client';
import { useEffect, useState } from 'react';
import { config } from 'config';

interface ExtendedError extends Error {
  data?: any;
}

export enum SocketState {
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  CONFLICT = 'conflict',
  ERROR = 'error'
}

const useSocket = (
  path: string,
  query: Record<string, string>,
  token: string,
): {
  state: SocketState;
  socket: Socket | null;
} => {
  const [ state, setState ] = useState<SocketState>(SocketState.CONNECTING);
  const [ socket, setSocket ] = useState<Socket | null>(null);
  const [ reconnectId, setReconnectId ] = useState<number>(0);

  const reconnect = () => {
    setTimeout(() => setReconnectId((reconnectId) => reconnectId + 1), 1000);
  };

  useEffect(() => {
    setState(SocketState.CONNECTING);

    const socket = io(`${config.signalerUrl}${path}`, {
      query,
      auth: { token },
      transports: [ 'websocket' ],
      autoConnect: false,
      reconnection: false,
      forceNew: true,
    });

    const onHello = () => {
      setSocket(socket);
      setState(SocketState.CONNECTED);
    };
    socket.on('hello', onHello);

    const onConflict = () => {
      socket.off('disconnect', onceDisconnect);
      socket.disconnect();
      setSocket(null);
      setState(SocketState.CONFLICT);
    };
    socket.on('conflict', onConflict);

    const onceDisconnect = () => {
      setSocket(null);
      setState(SocketState.CONNECTING);
      reconnect();
    };
    socket.once('disconnect', onceDisconnect);

    const onConnectError = (error: ExtendedError) => {
      setSocket(null);
      if (typeof error.data.type === 'string' && [ 'authenticationRequired', 'authenticationError', 'unauthorized', 'unspecifiedLessonId' ].includes(error.data.type)) {
        setState(SocketState.ERROR);
      } else {
        setState(SocketState.CONNECTING);
        reconnect();
      }
    };
    socket.once('connect_error', onConnectError);

    socket.connect();

    return () => {
      socket.off('hello', onHello);
      socket.off('conflict', onConflict);
      socket.off('disconnect', onceDisconnect);
      socket.off('connect_error', onConnectError);
      socket.close();
    };
  }, [ path, query, token, reconnectId ]);

  return { state, socket };
};

export default useSocket;
