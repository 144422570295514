import { useEffect, useMemo, useState } from 'react';
import useSocket, { SocketState } from './useSocket';
import Signaler from 'signalers/Signaler';

export enum SignalerState {
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  CONFLICT = 'conflict',
  ERROR = 'error',
}

const useSignaler = (
  lessonId: string,
  role: 'student' | 'tutor',
  token: string,
): {
  state: SignalerState;
  signaler: Signaler | null
} => {
  const query = useMemo<Record<string, string>>(() => ({ lessonId }), [ lessonId ]);
  const { state: socketState, socket } = useSocket(`/${role}`, query, token);

  const [ state, setState ] = useState<SignalerState>(SignalerState.CONNECTING);
  useEffect(() => {
    switch (socketState) {
      case SocketState.CONNECTING:
        setState(SignalerState.CONNECTING);
        break;

      case SocketState.CONNECTED:
        setState(SignalerState.CONNECTED);
        break;

      case SocketState.CONFLICT:
        setState(SignalerState.CONFLICT);
        break;

      case SocketState.ERROR:
        setState(SignalerState.ERROR);
        break;

      default:
        throw new Error('Invalid socket state: ' + socketState);
    }
  }, [ socketState ]);

  const [ signaler, setSignaler ] = useState<Signaler | null>(null);
  useEffect(() => {
    if (socket !== null) {
      setSignaler(new Signaler(socket));
    } else {
      setSignaler(null);
    }
  }, [ socket ]);

  return { state, signaler };
};

export default useSignaler;
