import Username from 'components/atoms/Username/Username';
import { config } from 'config';
import useStudentTutor from 'hooks/useStudentTutor';
import { memo, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  tutorId: string;
}

const StudentTutorLink = ({ tutorId }: PropsWithChildren<ComponentProps>) => {
  const tutor = useStudentTutor({ tutorId });

  const { t } = useTranslation();

  return (
    <a title={t("Show profile")} rel="noreferrer" className="text-white hover:underline" href={`${config.appDomain}/dashboard/student/tutor/${tutorId}/details`} target="_blank">
      {
        tutor && <Username username={tutor.name}/>
      }
    </a>
  );
};

export default memo(StudentTutorLink, (prevProps, nextProps) => {
  return prevProps.tutorId === nextProps.tutorId;
});