import { Peer } from 'hooks/usePeers';
import { useEffect, useState } from 'react';

const useStudentPeer = (peers: Peer[] | null): Peer | null => {
  const [ studentPeer, setStudentPeer ] = useState<Peer | null>(null);

  useEffect(() => {
    if (peers !== null) {
      const studentPeers = peers.filter((peer: Peer): boolean => peer.role === 'student');
      if (studentPeers.length === 0) {
        setStudentPeer(null);
      } else if (studentPeers.length === 1) {
        setStudentPeer(studentPeers[0]);
      } else {
        throw new Error(`Expected at most one student connected, there are ${studentPeers.length}`);
      }
    } else {
      setStudentPeer(null);
    }
  }, [ peers ]);

  return studentPeer;
};

export default useStudentPeer;
