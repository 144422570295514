import { useCallback, useState } from 'react';

const useChat = (): {
  isChatOpened: boolean,
  toggleChat: () => void,
} => {
  const [ isChatOpened, setChatVisibility ] = useState<boolean>(false);

  const toggleChat = useCallback(() => {
    setChatVisibility(!isChatOpened);
  }, [ isChatOpened ]);

  return {
    isChatOpened,
    toggleChat,
  };
};

export default useChat;
