import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { config } from 'config';

const useApi = (requestConfig?: AxiosRequestConfig): AxiosInstance => {
  const api = axios.create({
    baseURL: config.apiUrl,
    ...requestConfig,
  });

  return api;
};

export default useApi;