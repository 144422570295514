import React from "react";
import { App } from 'App';
import { config } from 'config';
import { StrictMode } from 'react';
import './index.css';
import './styles/partials/media.scss';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { createRoot } from 'react-dom/client';

switch (config.appId) {
  case 'tutly': {
    (i18n as any).use(initReactI18next).init({
        resources: {
          en: {
            translation: {
              'My lessons': 'My lessons',
              'Reload page': 'Reload page',
              'Back to dashboard': 'Back to dashboard',
              'Connection closed': 'Connection closed',
              'You have left the lesson': 'You have left the lesson',
              'Join again': 'Join again',
              'Dashboard': 'Dashboard',
              'The lesson has been canceled': 'The lesson has been canceled',
              'Lesson not found': 'Lesson not found',
              'Join a lesson': 'Join a lesson',
              'Hello': 'Hello, {{context}}',
              'Your browser cannot access': 'Your browser cannot access the {{context}}',
              'Error occurred at the operating system': 'Error occurred at the operating system, browser, or Web page level which prevented access to the {{context}}',
              'Context not found': '{{context}} not found',
              'Error occurred which prevented from being used': 'Error occurred which prevented the {{context}} from being used',
              'Lesson starts in': 'Lesson starts in ',
              'Show profile': 'Show profile',
              'Show details': 'Show details',
              'Lesson details': 'Lesson details',
              'Created at': 'Created at',
              'Starts at': 'Starts at',
              'Starts in': 'Starts in',
              'Duration': 'Duration',
              'minutes': '{{context}} minutes',
              'Webinar starts in': 'Webinar starts in ',
              'Join a webinar': 'Join a webinar',
              'You have left the webinar': 'You have left the webinar',
              'Start lesson': 'Start lesson',
              'The lesson has not started yet': 'The lesson has not started yet',
              'Webinar not found': 'Webinar not found',
              'Webinar has been canceled': 'Webinar has been canceled',
              'Webinar details': 'Webinar details',
              'Status': 'Status',
              'The lesson has ended': 'The lesson has ended',
              'My webinars': 'My webinars',
              'The lesson has been cancelled': 'The lesson has been cancelled',
              'Chat': 'Chat',
              'Camera': 'Camera',
              'Microphone': 'Microphone',
              'Share screen': 'Share screen',
              'Leave lesson': 'Leave lesson',
            },
          },
        },
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false,
        },
      },
    );

    switch (config.environment) {
      case 'development': {
        const existingScriptTag = document.getElementsByTagName('script')[0];
        const script = document.createElement('script');
        script.src = 'https://chat.classroom.stg.tutly.io/dist.js';

        existingScriptTag.parentNode?.append(script);
        break;
      }
      case 'staging': {
        const existingScriptTag = document.getElementsByTagName('script')[0];
        const script = document.createElement('script');
        script.src = 'https://chat.classroom.stg.tutly.io/dist.js';

        existingScriptTag.parentNode?.append(script);
        break;
      }
      case 'production': {
        const existingScriptTag = document.getElementsByTagName('script')[0];
        const script = document.createElement('script');
        script.src = 'https://chat.classroom.tutly.io/dist.js';

        existingScriptTag.parentNode?.append(script);
        break;
      }
    }
    break;
  }
  case 'fani': {
    (i18n as any).use(initReactI18next).init({
        resources: {
          en: {
            translation: {
              'My lessons': 'My meetings',
              'Reload page': 'Reload page',
              'Back to dashboard': 'Back to dashboard',
              'Connection closed': 'Connection closed',
              'You have left the lesson': 'You have left the meeting',
              'Join again': 'Join again',
              'Dashboard': 'Dashboard',
              'The lesson has been canceled': 'The meeting has been canceled',
              'Lesson not found': 'Meeting not found',
              'Join a lesson': 'Join a meeting',
              'Hello': 'Hello, {{context}}',
              'Your browser cannot access': 'Your browser cannot access the {{context}}',
              'Error occurred at the operating system': 'Error occurred at the operating system, browser, or Web page level which prevented access to the {{context}}',
              'Context not found': '{{context}} not found',
              'Error occurred which prevented from being used': 'Error occurred which prevented the {{context}} from being used',
              'Lesson starts in': 'Meeting starts in ',
              'Show profile': 'Show profile',
              'Show details': 'Show details',
              'Lesson details': 'Meeting details',
              'Created at': 'Created at',
              'Starts at': 'Starts at',
              'Starts in': 'Starts in',
              'Duration': 'Duration',
              'minutes': '{{context}} minutes',
              'Webinar starts in': 'Webinar starts in ',
              'Join a webinar': 'Join a webinar',
              'You have left the webinar': 'You have left the webinar',
              'Start lesson': 'Start meeting',
              'The lesson has not started yet': 'The meeting has not started yet',
              'Webinar not found': 'Webinar not found',
              'Webinar has been canceled': 'Webinar has been canceled',
              'Webinar details': 'Webinar details',
              'Status': 'Status',
              'The lesson has ended': 'The meeting has ended',
              'My webinars': 'My webinars',
              'The lesson has been cancelled': 'The meeting has been cancelled',
              'Chat': 'Chat',
              'Camera': 'Camera',
              'Microphone': 'Microphone',
              'Share screen': 'Share screen',
              'Leave lesson': 'Leave meeting',
            },
          },
        },
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false,
        },
      },
    );

    switch (config.environment) {
      case 'development': {
        const existingScriptTag = document.getElementsByTagName('script')[0];
        const script = document.createElement('script');
        script.src = 'https://chat.classroom.fani.pl/dist.js';

        existingScriptTag.parentNode?.append(script);
        break;
      }
      case 'staging': {
        const existingScriptTag = document.getElementsByTagName('script')[0];
        const script = document.createElement('script');
        script.src = 'https://chat.classroom.stg.fani.pl/dist.js';

        existingScriptTag.parentNode?.append(script);
        break;
      }
      case 'production': {
        const existingScriptTag = document.getElementsByTagName('script')[0];
        const script = document.createElement('script');
        script.src = 'https://chat.classroom.fani.pl/dist.js';

        existingScriptTag.parentNode?.append(script);
        break;
      }
    }
    break;
  }
}

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <StrictMode>
      <App/>
    </StrictMode>
  );
}