import { useCall } from '@ailogroup/salespoint-call-client';
import LoadingSpinner from 'components/molecules/LoadingSpinner/LoadingSpinner';
import MediaErrorToast from 'components/molecules/MediaErrorToast/MediaErrorToast';
import SignalerConflictDialog from 'components/molecules/SignalerConflictDialog/SignalerConflictDialog';
import SignalerErrorDialog from 'components/molecules/SignalerErrorDialog/SignalerErrorDialog';
import TutorWebinarFooter from 'components/molecules/TutorWebinarFooter/TutorWebinarFooter';
import TutorWebinarMedia from 'components/molecules/TutorWebinarMedia/TutorWebinarMedia';
import Chat from 'components/organisms/Chat/Chat';
import { config } from 'config';
import { HostMediaContext } from 'contexts/hostMediaContext';
import useCallUpstream from 'hooks/useCallUpstream';
import useChat from 'hooks/useChat';
import usePeers from 'hooks/usePeers';
import usePinnedPeer from 'hooks/usePinnedPeer';
import useSignaler, { SignalerState } from 'hooks/useSignaler';
import useSignalerPeers from 'hooks/useSignalerPeers';
import useSoundOnPeerChanges from 'hooks/useSoundOnPeerChanges';
import useStudentPeers from 'hooks/useStudentPeers';
import useTutorWebinarConversationId from 'hooks/useTutorWebinarConversationId';
import useTutorWebinarStudents from 'hooks/useTutorWebinarStudents';
import { ITutorWebinar } from 'models/tutor-webinar';
import { IUser } from 'models/user';
import { useContext, useMemo } from 'react';
import styles from './TutorWebinarRoom.module.scss';

interface ComponentProps {
  user: IUser;
  webinar: ITutorWebinar;
  enterKey: string;
  endCallback: () => void;
}

const TutorWebinarRoom = ({ user, webinar, enterKey, endCallback }: ComponentProps) => {
  const { isChatOpened, toggleChat } = useChat();
  const { microphone, camera, display, errorMicrophone, errorCamera } = useContext(HostMediaContext);
  const { state: signalerState, signaler } = useSignaler(webinar.id, 'tutor', enterKey);
  const signalerPeers = useSignalerPeers(signaler);

  const callEnabled = useMemo<boolean>(() => signalerState === SignalerState.CONNECTED, [ signalerState ]);
  const { peerId: tutorCallId, peers: callPeers } = useCall({
    enabled: callEnabled,
    serverUrl: config.callServerUrl,
    roomId: webinar.id,
    microphone,
    camera,
    display,
    consumePreferences: null,
  });

  const conversationId = useTutorWebinarConversationId({ webinarId: webinar.id });
  const students = useTutorWebinarStudents({ webinarId: webinar.id });

  useCallUpstream(signaler, tutorCallId);

  const peers = usePeers(signalerPeers, callPeers);
  const studentPeers = useStudentPeers(peers);

  useSoundOnPeerChanges(peers);

  const { pinnedPeerId, togglePinnedPeer } = usePinnedPeer();

  switch (signalerState) {
    case SignalerState.CONFLICT: {
      return <SignalerConflictDialog/>;
    }
    case SignalerState.ERROR: {
      return <SignalerErrorDialog/>;
    }
    case SignalerState.CONNECTING: {
      return <LoadingSpinner/>;
    }
    case SignalerState.CONNECTED: {
      return (
        <>
          <section className={styles.wrapper}>
            <div className={styles.streams}>
              <header className="h-6"/>
              <TutorWebinarMedia user={user} studentPeers={studentPeers} togglePinnedPeer={togglePinnedPeer} pinnedPeerId={pinnedPeerId} webinar={webinar} students={students}/>
              <TutorWebinarFooter webinar={webinar} toggleChat={toggleChat} endCallback={endCallback} conversationId={conversationId}/>
            </div>

            {
              isChatOpened && conversationId && (
                <aside className={styles.chat} data-pinned={!!pinnedPeerId}>
                  <Chat conversationId={conversationId} userRole="tutor"/>
                </aside>
              )
            }
          </section>

          <MediaErrorToast context="microphone" error={errorMicrophone}/>
          <MediaErrorToast context="camera" error={errorCamera}/>
        </>
      );
    }
  }
};

export default TutorWebinarRoom;