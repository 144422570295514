import VideoTrack from 'components/atoms/VideoTrack/VideoTrack';
import { PropsWithChildren, ReactElement } from 'react';

interface ComponentProps {
  track: MediaStreamTrack | null;
  isMuted: boolean;
  username?: ReactElement;
  avatar?: ReactElement;
}

const Video = ({ track, isMuted, username, avatar }: PropsWithChildren<ComponentProps>) => {
  if (track) {
    return (
      <div className="h-full overflow-hidden relative w-full">
        <div className="flex justify-center left-2/4 absolute top-2/4 -translate-x-2/4 -translate-y-2/4 aspect-video w-full h-full">
          <VideoTrack track={track}/>
        </div>
        {isMuted && <span className="material-icons absolute top-2 right-2 text-white">mic_off</span>}
        <p className="absolute bottom-2 left-2 text-white">{username}</p>
      </div>
    );
  } else {
    return (
      <div className="h-full overflow-hidden relative w-full">
        <div className="items-center flex h-full justify-center relative w-full h-full">
          {avatar}
        </div>
        {isMuted && <span className="material-icons absolute top-2 right-2 text-white">mic_off</span>}
        <p className="absolute bottom-2 left-2 text-white">{username}</p>
      </div>
    );
  }
};

export default Video;