import LessonErrorMessage from 'components/molecules/LessonErrorMessage/LessonErrorMessage';
import LoadingSpinner from 'components/molecules/LoadingSpinner/LoadingSpinner';
import UnauthorizedSpinner from 'components/molecules/UnauthorizedSpinner/UnauthorizedSpinner';
import TutorLessonEnd from 'components/organisms/TutorLessonEnd/TutorLessonEnd';
import TutorLessonLobby from 'components/organisms/TutorLessonLobby/TutorLessonLobby';
import TutorLessonRoom from 'components/organisms/TutorLessonRoom/TutorLessonRoom';
import useLessonClassroom, { LessonClassroomStatus } from 'hooks/useLessonClassroom';
import useTutorLesson from 'hooks/useTutorLesson';
import { IUser } from 'models/user';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import redirectToAuth from 'utilities/redirectToAuth';
import useAuthToken from 'hooks/useAuthToken';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  user: IUser;
}

const TutorLesson = ({ user }: PropsWithChildren<ComponentProps>) => {
  const { t } = useTranslation();

  const [ joined, setJoined ] = useState<boolean>(false);
  const join = useCallback(() => {
    setJoined(true);
  }, []);

  const [ ended, setEnded ] = useState<boolean>(false);
  const end = useCallback(() => {
    setJoined(false);
    setEnded(true);
  }, []);

  const reset = useCallback(() => {
    setJoined(false);
    setEnded(false);
  }, []);

  const params = useParams<{ lessonId: string }>();
  const lessonId = useMemo(() => {
    if (params.lessonId !== undefined) {
      return params.lessonId;
    } else {
      throw new Error('lessonId parameter does not exist');
    }
  }, [ params ]);
  const { status, refreshStatusFn, enterKey } = useLessonClassroom(lessonId, 'tutor');
  const { lesson, startLessonFn, refreshLessonFn } = useTutorLesson({ lessonId });
  const { removeAuthToken } = useAuthToken();

  useEffect(() => {
    if (status === LessonClassroomStatus.UNAUTHENTICATED) {
      removeAuthToken();
      redirectToAuth();
    }
  }, [ status ]);

  useEffect(() => {
    let interval: any;

    if (status === LessonClassroomStatus.LESSON_NOT_STARTED) {
      interval = setInterval(() => {
        refreshStatusFn();
      }, 1500);
    } else {
      refreshLessonFn();
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [ status, refreshStatusFn, refreshLessonFn ]);

  if (ended) {
    return <TutorLessonEnd joinAgainCallback={reset}/>;
  } else {
    switch (status) {
      case LessonClassroomStatus.LOADING: {
        return <LoadingSpinner/>;
      }
      case LessonClassroomStatus.UNAUTHENTICATED:
      case LessonClassroomStatus.UNAUTHORIZED: {
        return <UnauthorizedSpinner/>;
      }
      case LessonClassroomStatus.LESSON_CANCELLED: {
        return (
          <div className="flex flex-col h-full min-h-screen justify-center items-center py-6">
            <LessonErrorMessage role="tutor">{t("The lesson has been canceled")}</LessonErrorMessage>
          </div>
        );
      }
      case LessonClassroomStatus.LESSON_NOT_FOUND: {
        return (
          <div className="flex flex-col h-full min-h-screen justify-center items-center py-6">
            <LessonErrorMessage role="tutor">{t("Lesson not found")}</LessonErrorMessage>
          </div>
        );
      }
      case LessonClassroomStatus.LESSON_NOT_STARTED: {
        if (lesson === null) {
          return <LoadingSpinner/>;
        } else {
          return <TutorLessonLobby user={user} lesson={lesson} startCallback={startLessonFn} joinCallback={join}/>;
        }
      }
      case LessonClassroomStatus.LESSON_FINISHED: {
        return (
          <div className="flex flex-col h-full min-h-screen justify-center items-center py-6">
            <LessonErrorMessage role="student">{t("The lesson has ended")}</LessonErrorMessage>
          </div>
        );
      }
      case LessonClassroomStatus.READY: {
        if (lesson === null || enterKey === null) {
          return <LoadingSpinner/>;
        } else if (joined) {
          return <TutorLessonRoom user={user} lesson={lesson} enterKey={enterKey} endCallback={end}/>;
        } else {
          return <TutorLessonLobby user={user} lesson={lesson} startCallback={startLessonFn} joinCallback={join}/>;
        }
      }
    }
  }
};

export default TutorLesson;