import { MediaErrorName } from 'models/media-error-name.enum';
import { Toast } from 'primereact/toast';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  context: 'camera' | 'microphone';
  error: DOMException | null;
}

const MediaErrorToast = ({ context, error }: PropsWithChildren<ComponentProps>) => {
  const toastRef = useRef<Toast>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (toastRef.current) {
      toastRef.current.clear();

      if (error?.name) {
        switch (error.name) {
          case MediaErrorName.ABORT_ERROR: {
            toastRef.current.show({ severity: 'error', summary: 'Error', detail: t('Error occurred which prevented from being used', { context }), closable: true, sticky: false });
            break;
          }
          case MediaErrorName.NOT_FOUND_ERROR: {
            toastRef.current.show({ severity: 'warn', summary: 'Warning', detail: t('Context not found', { context }), closable: true, sticky: false });
            break;
          }
          case MediaErrorName.NOT_READABLE_ERROR: {
            toastRef.current.show({ severity: 'error', summary: 'Error', detail: t('Error occurred at the operating system', { context }), closable: true, sticky: false });
            break;
          }
          case MediaErrorName.OVERCONSTRAINED_ERROR: {
            toastRef.current.show({ severity: 'error', summary: 'Error', detail: error.message, closable: true, sticky: false });
            break;
          }
          case MediaErrorName.NOT_ALLOWED_ERROR:
          case MediaErrorName.SECURITY_ERROR:
          case MediaErrorName.TYPE_ERROR: {
            toastRef.current.show({ severity: 'error', summary: 'Error', detail: t('Your browser cannot access', { context }), closable: true, sticky: false });
            break;
          }
          default: {
            toastRef.current.show({ severity: 'warn', summary: 'Warning', detail: error.message ?? error.name, closable: true, sticky: false });
          }
        }
      }
    }
  }, [ context, error ]);

  return (
    <Toast ref={toastRef} position="top-right"/>
  );
};

export default MediaErrorToast;