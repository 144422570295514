import LoadingSpinner from 'components/molecules/LoadingSpinner/LoadingSpinner';
import { useEffect } from 'react';
import redirectToAuth from 'utilities/redirectToAuth';
import useAuthToken from 'hooks/useAuthToken';

const UnauthorizedSpinner = () => {
  const { removeAuthToken } = useAuthToken();

  useEffect(() => {
    const timeout = setTimeout(() => {
      removeAuthToken();
      redirectToAuth();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (<LoadingSpinner/>);
};

export default UnauthorizedSpinner;