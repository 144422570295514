import useCamera from 'hooks/useCamera';
import useDisplay from 'hooks/useDisplay';
import useMicrophone from 'hooks/useMicrophone';
import { createContext, PropsWithChildren } from 'react';

interface Context {
  camera: MediaStreamTrack | null;
  microphone: MediaStreamTrack | null;
  display: MediaStreamTrack | null;
  startCamera: () => void;
  stopCamera: () => void;
  startMicrophone: () => void;
  stopMicrophone: () => void;
  startDisplay: () => void;
  stopDisplay: () => void;
  errorMicrophone: DOMException | null;
  errorCamera: DOMException | null;
}

const HostMediaContext = createContext<Context>({
  camera: null,
  microphone: null,
  display: null,
  startCamera: () => {
  },
  stopCamera: () => {
  },
  startMicrophone: () => {
  },
  stopMicrophone: () => {
  },
  startDisplay: () => {
  },
  stopDisplay: () => {
  },
  errorMicrophone: null,
  errorCamera: null,
});

const HostMediaProvider = ({ children }: PropsWithChildren<Record<string, any>>) => {
  const { track: microphone, error: errorMicrophone, start: startMicrophone, stop: stopMicrophone } = useMicrophone();
  const { track: camera, error: errorCamera, start: startCamera, stop: stopCamera } = useCamera();
  const { track: display, start: startDisplay, stop: stopDisplay } = useDisplay();

  return (
    <HostMediaContext.Provider value={{
      microphone,
      errorMicrophone,
      startMicrophone,
      stopMicrophone,
      camera,
      errorCamera,
      startCamera,
      stopCamera,
      display,
      startDisplay,
      stopDisplay,
    }}>
      {children}
    </HostMediaContext.Provider>
  );
};

export {
  HostMediaContext,
  HostMediaProvider,
};