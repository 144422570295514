import { Button } from 'primereact/button';
import { memo, PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type MediaSwitchType = 'camera' | 'microphone' | 'share';

interface ComponentProps {
  type: MediaSwitchType;
  isEnabled: boolean;
  enable: () => void;
  disable: () => void;
  enabledColor?: string;
  disabledColor?: string;
}

const MediaSwitch = ({ type, isEnabled, disable, enable, enabledColor = 'primary', disabledColor = 'danger' }: PropsWithChildren<ComponentProps>) => {
  const toggleHandler = useCallback(() => {
    if (isEnabled) {
      disable();
    } else {
      enable();
    }
  }, [ isEnabled, disable, enable ]);

  const { t } = useTranslation();

  switch (type) {
    case 'camera': {
      return (
        <Button className={isEnabled ? `p-button-rounded p-button-${enabledColor}` : `p-button-rounded p-button-${disabledColor}`} onClick={toggleHandler} tooltip={t("Camera")} tooltipOptions={{ position: 'top' }}>
          {
            isEnabled ? <span className="material-icons">videocam</span> : <span className="material-icons">videocam_off</span>
          }
        </Button>
      );
    }
    case 'microphone': {
      return (
        <Button className={isEnabled ? `p-button-rounded p-button-${enabledColor}` : `p-button-rounded p-button-${disabledColor}`} onClick={toggleHandler} tooltip={t("Microphone")} tooltipOptions={{ position: 'top' }}>
          {
            isEnabled ? <span className="material-icons">mic</span> : <span className="material-icons">mic_off</span>
          }
        </Button>
      );
    }
    case 'share': {
      return (
        <Button className={isEnabled ? `p-button-rounded p-button-${enabledColor}` : `p-button-rounded p-button-${disabledColor}`} onClick={toggleHandler} tooltip={t("Share screen")} tooltipOptions={{ position: 'top' }}>
          {
            isEnabled ? <span className="material-icons">stop_screen_share</span> : <span className="material-icons">screen_share</span>
          }
        </Button>
      );
    }
  }
};

export default memo(MediaSwitch, (prevProps, nextProps) => {
  return prevProps.isEnabled === nextProps.isEnabled;
});