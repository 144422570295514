import { memo, PropsWithChildren } from 'react';

interface ComponentProps {
  username: string;
}

const Username = ({ username }: PropsWithChildren<ComponentProps>) => {
  return <>{username}</>;
};

export default memo(Username, (prevProps, nextProps) => {
  return prevProps.username === nextProps.username;
});