import { formatDistanceToNow, isBefore, isValid, parseISO } from 'date-fns';
import { memo, PropsWithChildren, useEffect, useState } from 'react';

interface ComponentProps {
  startTime: string;
  label?: string;
}

const Countdown = ({ startTime, label }: PropsWithChildren<ComponentProps>) => {
  const [ countdownTo ] = useState<Date>(() => parseISO(startTime));
  const [ distance, setDistance ] = useState<string>(() => formatDistanceToNow(countdownTo));

  useEffect(() => {
    const interval = setInterval(() => {
      if (isValid(countdownTo) && isBefore(new Date(), countdownTo)) {
        setDistance(formatDistanceToNow(countdownTo));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [ countdownTo ]);

  if (label) {
    return (<>{label}{distance}</>);
  } else {
    return (<>{distance}</>);
  }
};

export default memo(Countdown, (prevProps, nextProps) => {
  return prevProps.startTime === nextProps.startTime && prevProps.label === nextProps.label;
});