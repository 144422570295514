import { config } from 'config';
import { Button } from 'primereact/button';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const TutorWebinarEnd = ({ joinAgainCallback }: { joinAgainCallback: () => void }) => {
  const { t } = useTranslation();

  const dashboardRedirectHandler = useCallback(() => {
    window.open(`${config.appDomain}/dashboard/tutor`, '_self');
  }, []);

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="grid grid-rows-3 gap-y-3">
        <h1 className="font-semibold text-center text-xl">{t("You have left the webinar")}</h1>
        <Button className="w-full" label={t("Join again")} icon="pi pi-chevron-left" onClick={joinAgainCallback}/>
        <Button className="w-full p-button-outlined" label={t("Dashboard")} icon="pi pi-th-large" onClick={dashboardRedirectHandler}/>
      </div>
    </div>
  );
};

export default TutorWebinarEnd;