import useApi from 'hooks/useApi';
import useAuthToken from 'hooks/useAuthToken';
import { IConversation } from 'models/conversation';
import { UserRole } from 'models/user-role';
import { useEffect, useMemo, useState } from 'react';
import redirectToAuth from 'utilities/redirectToAuth';
import { AxiosError } from 'axios';
import { BackendError } from 'models/backend-error';

interface IReturnType {
  conversation: IConversation | null;
  unreadMessages: number;
  refreshUnreadMessages: () => void;
  resetUnreadMessages: () => void;
}

const useConversation = ({ conversationId, userRole }: { conversationId: string | null, userRole: UserRole }): IReturnType => {
  const [ unreadMessages, setUnreadMessages ] = useState<number>(0);
  const [ conversation, setConversation ] = useState<IConversation | null>(null);

  const { getAuthToken, removeAuthToken } = useAuthToken();
  const authToken = useMemo<string | null>(() => getAuthToken() ?? null, [ getAuthToken ]);

  const api = useApi();

  const refreshUnreadMessages = () => {
    if (authToken && userRole && conversationId) {
      api.request<IConversation>({
        method: 'GET',
        url: `${userRole}/conversations/${conversationId}`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        setUnreadMessages(data.unreadMessages);
      }).catch((error: AxiosError<BackendError>) => {
        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  };

  const resetUnreadMessages = () => {
    setUnreadMessages(0);
  };

  useEffect(() => {
    if (authToken && userRole && conversationId) {
      api.request<IConversation>({
        method: 'GET',
        url: `${userRole}/conversations/${conversationId}`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        setConversation(data);
      }).catch((error: AxiosError<BackendError>) => {
        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  }, [ authToken, userRole, conversationId ]);

  return {
    conversation,
    unreadMessages,
    refreshUnreadMessages,
    resetUnreadMessages,
  };
};

export default useConversation;
