import useApi from 'hooks/useApi';
import useAuthToken from 'hooks/useAuthToken';
import { ITutor } from 'models/tutor';
import { useEffect, useMemo, useState } from 'react';
import redirectToAuth from 'utilities/redirectToAuth';
import { AxiosError } from 'axios';
import { BackendError } from 'models/backend-error';

const useStudentTutor = ({ tutorId }: { tutorId: string }): ITutor | null => {
  const [ tutor, setTutor ] = useState<ITutor | null>(null);

  const { getAuthToken, removeAuthToken } = useAuthToken();
  const authToken = useMemo<string | null>(() => getAuthToken() ?? null, [ getAuthToken ]);

  const api = useApi();

  useEffect(() => {
    if (authToken) {
      api.request<ITutor>({
        method: 'GET',
        url: `student/tutors/${tutorId}`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        setTutor(data);
      }).catch((error: AxiosError<BackendError>) => {
        setTutor(null);

        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  }, [ tutorId, authToken ]);

  return tutor;
};

export default useStudentTutor;
