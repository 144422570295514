import { AxiosError } from 'axios';
import useApi from 'hooks/useApi';
import useAuthToken from 'hooks/useAuthToken';
import { BackendError } from 'models/backend-error';
import { BackendErrorType } from 'models/backend-error-type.enum';
import { ITutorLesson } from 'models/tutor-lesson';
import { useCallback, useEffect, useMemo, useState } from 'react';
import redirectToAuth from 'utilities/redirectToAuth';
import redirectToTutorPricing from 'utilities/redirectToTutorPricing';

const useTutorLesson = ({ lessonId }: { lessonId: string }): {
  lesson: ITutorLesson | null,
  refreshLessonFn: () => void,
  startLessonFn: () => void,
} => {
  const [ lesson, setLesson ] = useState<ITutorLesson | null>(null);

  const { getAuthToken, removeAuthToken } = useAuthToken();
  const authToken = useMemo<string | null>(() => getAuthToken() ?? null, [ getAuthToken ]);

  const api = useApi();

  useEffect(() => {
    if (authToken) {
      api.request<ITutorLesson>({
        method: 'GET',
        url: `tutor/lessons/${lessonId}`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        setLesson(data);
      }).catch((error: AxiosError<BackendError>) => {
        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  }, [ authToken, lessonId ]);

  const refresh = useCallback(() => {
    if (authToken) {
      api.request<ITutorLesson>({
        method: 'GET',
        url: `tutor/lessons/${lessonId}`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        setLesson(data);
      }).catch((error: AxiosError<BackendError>) => {
        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  }, [ authToken, lessonId ]);

  const start = useCallback(() => {
    if (authToken) {
      api.request<ITutorLesson>({
        method: 'POST',
        url: `tutor/lessons/${lessonId}/start`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        setLesson(data);
      }).catch((error: AxiosError<BackendError>) => {
        if (error?.response?.data?.type) {
          switch (error?.response?.data?.type) {
            case BackendErrorType.AUTHENTICATION_REQUIRED:
            case BackendErrorType.AUTHENTICATION_ERROR: {
              removeAuthToken();
              redirectToAuth();
              break;
            }
            case BackendErrorType.TUTOR_LESSON_TIME_UNAVAILABLE: {
              redirectToTutorPricing();
              break;
            }
          }
        }
      });
    } else {
      redirectToAuth();
    }
  }, [ authToken, lessonId ]);

  return {
    lesson,
    refreshLessonFn: refresh,
    startLessonFn: start,
  };
};

export default useTutorLesson;
