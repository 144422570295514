import { PropsWithChildren, useEffect, useRef } from 'react';

interface ComponentProps {
  track: MediaStreamTrack;
}

const VideoTrack = ({ track }: PropsWithChildren<ComponentProps>) => {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.srcObject = new MediaStream([ track ]);
    }
  }, [ track ]);

  return (
    <video ref={ref} autoPlay playsInline muted={true}/>
  );
};

export default VideoTrack;