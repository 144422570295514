import useApi from './useApi';
import useAuthToken from './useAuthToken';
import { useEffect, useMemo, useState } from 'react';
import { UserRole } from 'models/user-role';
import { BackendErrorType } from '../models/backend-error-type.enum';

export enum WebinarClassroomStatus {
  LOADING = 'loading',
  UNAUTHENTICATED = 'unauthenticated',
  UNAUTHORIZED = 'unauthorized',
  WEBINAR_NOT_FOUND = 'webinarNotFound',
  WEBINAR_CANCELLED = 'webinarCancelled',
  READY = 'ready',
}

const useWebinarClassroom = (webinarId: string, role: UserRole): { status: WebinarClassroomStatus, enterKey: string | null } => {
  const { getAuthToken } = useAuthToken();
  const authToken = useMemo<string | null>(() => getAuthToken() ?? null, [ getAuthToken ]);
  const [ status, setStatus ] = useState<WebinarClassroomStatus>(WebinarClassroomStatus.LOADING);
  const [ enterKey, setEnterKey ] = useState<string | null>(null);
  const api = useApi();

  useEffect(() => {
    if (authToken !== null) {
      api.request<{ key: string }>({
        method: 'POST',
        url: role + '/webinars/' + webinarId + '/classroom/enter',
        headers: {
          authorization: 'Bearer ' + authToken,
        },
      }).then((response) => {
        setEnterKey(response.data.key);
        setStatus(WebinarClassroomStatus.READY);
      }).catch((error) => {
        if (error.response.data.type) {
          switch (error.response.data.type) {
            case BackendErrorType.AUTHENTICATION_REQUIRED:
            case BackendErrorType.AUTHENTICATION_ERROR:
              setStatus(WebinarClassroomStatus.UNAUTHENTICATED);
              return;
            case BackendErrorType.USER_IS_NOT_A_STUDENT:
            case BackendErrorType.USER_IS_NOT_A_TUTOR:
              setStatus(WebinarClassroomStatus.UNAUTHORIZED);
              return;
            case BackendErrorType.WEBINAR_NOT_FOUND:
            case BackendErrorType.STUDENT_NOT_ENROLLED_FOR_WEBINAR:
              setStatus(WebinarClassroomStatus.WEBINAR_NOT_FOUND);
              return;
            case BackendErrorType.WEBINAR_CANCELLED:
              setStatus(WebinarClassroomStatus.WEBINAR_CANCELLED);
              return;
          }
        }

        throw error;
      });
    } else {
      setStatus(WebinarClassroomStatus.UNAUTHENTICATED);
    }
  }, [ webinarId, role, authToken ]);

  return { status, enterKey };
};

export default useWebinarClassroom;
