import Countdown from 'components/atoms/Countdown/Countdown';
import WebinarStatusTag from 'components/molecules/WebinarStatusTag/WebinarStatusTag';
import { parseISO } from 'date-fns';
import { IStudentWebinar } from 'models/student-webinar';
import { ITutorWebinar } from 'models/tutor-webinar';
import { Dialog } from 'primereact/dialog';
import { memo, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  webinar: IStudentWebinar | ITutorWebinar;
  onHide: () => void;
}

const WebinarDetailsDialog = ({ webinar, onHide }: PropsWithChildren<ComponentProps>) => {
  const [ creationTime, setCreationTime ] = useState<string | undefined>();
  const [ startTime, setStartTime ] = useState<string | undefined>();

  const { t } = useTranslation();

  useEffect(() => {
    setCreationTime(new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' }).format(parseISO(webinar.creationTime)));
  }, [ webinar ]);

  useEffect(() => {
    setStartTime(new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' }).format(parseISO(webinar.date.startTime)));
  }, [ webinar ]);

  return (
    <Dialog header={t("Webinar details")}
            visible={true}
            closable={true}
            className="w-full sm:w-auto"
            onHide={onHide}>
      <div className="bg-white w-full sm:max-w-2xl shadow overflow-hidden sm:rounded-lg">
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{t("Created at")}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{creationTime}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{t("Starts at")}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{startTime}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{t("Starts in")}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Countdown startTime={webinar.date.startTime}/>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{t("Duration")}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{t("minutes", { context: webinar.date.duration })}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{t("Status")}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{<WebinarStatusTag status={webinar.status}/>}</dd>
            </div>
          </dl>
        </div>
      </div>
    </Dialog>
  );
};

export default memo(WebinarDetailsDialog, (prevProps, nextProps) => {
  return prevProps.webinar.id === nextProps.webinar.id;
});