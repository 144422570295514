import { AxiosError } from 'axios';
import useApi from 'hooks/useApi';
import useAuthToken from 'hooks/useAuthToken';
import { BackendError } from 'models/backend-error';
import { IStudentLesson } from 'models/student-lesson';
import { useCallback, useEffect, useMemo, useState } from 'react';
import redirectToAuth from 'utilities/redirectToAuth';

const useStudentLesson = ({ lessonId }: { lessonId: string }): {
  lesson: IStudentLesson | null,
  refreshLessonFn: () => void,
} => {
  const [ lesson, setLesson ] = useState<IStudentLesson | null>(null);

  const { getAuthToken, removeAuthToken } = useAuthToken();
  const authToken = useMemo<string | null>(() => getAuthToken() ?? null, [ getAuthToken ]);

  const api = useApi();

  useEffect(() => {
    if (authToken) {
      api.request<IStudentLesson>({
        method: 'GET',
        url: `student/lessons/${lessonId}`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        setLesson(data);
      }).catch((error: AxiosError<BackendError>) => {
        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  }, [ authToken, lessonId ]);

  const refresh = useCallback(() => {
    if (authToken) {
      api.request<IStudentLesson>({
        method: 'GET',
        url: `student/lessons/${lessonId}`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        setLesson(data);
      }).catch((error: AxiosError<BackendError>) => {
        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  }, [ authToken, lessonId ]);

  return {
    lesson,
    refreshLessonFn: refresh,
  };
};

export default useStudentLesson;
