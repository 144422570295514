import { Peer } from 'hooks/usePeers';
import { useEffect, useState } from 'react';

const useStudentPeers = (peers: Peer[] | null): Peer[] => {
  const [ studentPeers, setStudentPeers ] = useState<Peer[]>([]);

  useEffect(() => {
    if (peers !== null) {
      setStudentPeers(peers.filter((peer: Peer): boolean => peer.role === 'student'));
    } else {
      setStudentPeers([]);
    }
  }, [ peers ]);

  return studentPeers;
};

export default useStudentPeers;
