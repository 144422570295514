import useApi from 'hooks/useApi';
import useAuthToken from 'hooks/useAuthToken';
import { IUser } from 'models/user';
import { Avatar } from 'primereact/avatar';
import { Skeleton } from 'primereact/skeleton';
import { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import redirectToAuth from 'utilities/redirectToAuth';
import toNameInitials from 'utilities/toNameInitials';
import { AxiosError } from 'axios';
import { BackendError } from 'models/backend-error';

interface ComponentProps {
  user: IUser;
}

const UserAvatar = ({ user }: PropsWithChildren<ComponentProps>) => {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ avatar, setAvatar ] = useState<string | undefined>();
  const [ label, setLabel ] = useState<string | undefined>();

  const { getAuthToken, removeAuthToken } = useAuthToken();
  const authToken = useMemo<string | null>(() => getAuthToken() ?? null, [ getAuthToken ]);

  const api = useApi();

  useEffect(() => {
    if (authToken) {
      setLoading(true);

      api.request<Blob>({
        method: 'GET',
        url: `users/current/picture`,
        responseType: 'blob',
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        if (data.size) {
          return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);

            reader.onloadend = () => {
              resolve(reader.result as string);
            };
          });
        } else {
          return Promise.resolve(null);
        }
      }).then((avatar) => {
        setLoading(false);

        if (avatar) {
          setAvatar(avatar);
        }
      }).catch((error: AxiosError<BackendError>) => {
        setLoading(false);

        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  }, [ authToken ]);

  useEffect(() => {
    setLabel(toNameInitials(user.name));
  }, [ user ]);

  if (loading) {
    return (
      <Skeleton shape="circle" size="6rem" className="rounded-full"/>
    );
  } else if (avatar) {
    return (
      <Avatar image={avatar} shape="circle" size="xlarge" style={{ width: '100px', height: '100px', 'borderRadius': '50%' }}/>
    );
  } else {
    return (
      <Avatar shape="circle" size="xlarge" icon="pi pi-user" label={label} style={{ width: '100px', height: '100px', 'borderRadius': '50%' }}/>
    );
  }
};

export default memo(UserAvatar, (prevProps, nextProps) => {
  return prevProps.user.id === nextProps.user.id;
});