import { useCallback, useEffect, useState } from 'react';

const Clock = () => {
  const getHour = useCallback(() => {
    return new Date().getHours() > 9 ? new Date().getHours() : `0${new Date().getHours()}`;
  }, []);

  const getMinute = useCallback(() => {
    return new Date().getMinutes() > 9 ? new Date().getMinutes() : `0${new Date().getMinutes()}`;
  }, []);

  const [ time, setTime ] = useState<string>(() => `${getHour()}:${getMinute()}`);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(`${getHour()}:${getMinute()}`);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [ getHour, getMinute ]);

  return (
    <>{time}</>
  );
};

export default Clock;