import { UserRole } from 'models/user-role';
import { memo, PropsWithChildren } from 'react';

interface ComponentProps {
  conversationId: string;
  userRole: UserRole;
}

const Chat = ({ conversationId, userRole }: PropsWithChildren<ComponentProps>) => {
  const webComponent = (<tutly-classroom-chat conversationid={conversationId} usercontext={userRole}/>);

  return (
    <div className="bg-white h-full relative w-screen lg:w-128">
      {webComponent}
    </div>
  );
};

export default memo(Chat, (prevProps, nextProps) => {
  return prevProps.conversationId === nextProps.conversationId && prevProps.userRole === nextProps.userRole;
});