import { LessonStatus } from 'models/lesson-status.enum';
import { Tag } from 'primereact/tag';
import { memo, PropsWithChildren } from 'react';

interface ComponentProps {
  status: LessonStatus;
}

const LessonStatusTag = ({ status }: PropsWithChildren<ComponentProps>) => {
  switch (status) {
    case LessonStatus.SCHEDULED: {
      return (<Tag value={status} severity="info"/>);
    }
    case LessonStatus.STARTED: {
      return (<Tag value={status} severity="success"/>);
    }
    case LessonStatus.FINISHED: {
      return (<Tag value={status} severity="success"/>);
    }
    case LessonStatus.CANCELED: {
      return (<Tag value={status} severity="danger"/>);
    }
    default: {
      return (<>{status}</>);
    }
  }
};

export default memo(LessonStatusTag, (prevProps, nextProps) => {
  return prevProps.status === nextProps.status;
});