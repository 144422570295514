import { AxiosError } from 'axios';
import { BackendError } from 'models/backend-error';
import { IUser } from 'models/user';
import { useEffect, useMemo, useState } from 'react';
import redirectToAuth from 'utilities/redirectToAuth';
import useApi from './useApi';
import useAuthToken from './useAuthToken';

const useUser = (): IUser | null => {
  const [ user, setUser ] = useState<IUser | null>(null);

  const { getAuthToken, removeAuthToken } = useAuthToken();
  const authToken = useMemo<string | null>(() => getAuthToken() ?? null, [ getAuthToken ]);

  const api = useApi();

  useEffect(() => {
    if (authToken) {
      api.request<IUser>({
        method: 'GET',
        url: `users/current`,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        setUser(data);
      }).catch((error: AxiosError<BackendError>) => {
        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  }, [ authToken ]);

  return user;
};

export default useUser;
