import Countdown from 'components/atoms/Countdown/Countdown';
import LessonStatusTag from 'components/molecules/LessonStatusTag/LessonStatusTag';
import { parseISO } from 'date-fns';
import { IStudentLesson } from 'models/student-lesson';
import { ITutorLesson } from 'models/tutor-lesson';
import { Dialog } from 'primereact/dialog';
import { memo, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  lesson: IStudentLesson | ITutorLesson;
  onHide: () => void;
}

const LessonDetailsDialog = ({ lesson, onHide }: PropsWithChildren<ComponentProps>) => {
  const [ creationTime, setCreationTime ] = useState<string | undefined>();
  const [ startTime, setStartTime ] = useState<string | undefined>();

  const { t } = useTranslation();

  useEffect(() => {
    setCreationTime(new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' }).format(parseISO(lesson.creationTime)));
  }, [ lesson ]);

  useEffect(() => {
    setStartTime(new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' }).format(parseISO(lesson.startTime)));
  }, [ lesson ]);

  return (
    <Dialog header={t("Lesson details")} visible={true} closable={true} className="w-full sm:w-auto" onHide={onHide}>
      <div className="bg-white w-full sm:max-w-2xl shadow overflow-hidden sm:rounded-lg">
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{t("Created at")}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{creationTime}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{t("Starts at")}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{startTime}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{t("Starts in")}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Countdown startTime={lesson.startTime}/>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{t("Duration")}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{t("minutes", { context: lesson.duration })}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{t("Status")}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{<LessonStatusTag status={lesson.status}/>}</dd>
            </div>
          </dl>
        </div>
      </div>
    </Dialog>
  );
};

export default memo(LessonDetailsDialog, (prevProps, nextProps) => {
  return prevProps.lesson.id === nextProps.lesson.id;
});