const toNameInitials = (name: unknown): string => {
  if (typeof name === 'string') {
    const [ a, b ] = name.split(' ');

    if (typeof a === 'string' && typeof b === 'string') {
      if (a.length > 0 && b.length > 0) {
        return a[0] + b[0];
      }

      if (a.length > 0 && b.length === 0) {
        if (a.length >= 2) {
          return a[0] + a[1];
        }

        return a[0];
      }

      if (b.length > 0 && a.length === 0) {
        if (b.length >= 2) {
          return b[0] + b[1];
        }

        return b[0];
      }

      return '';
    }

    if (typeof a === 'string' && typeof b !== 'string') {
      if (a.length >= 2) {
        return a[0] + a[1];
      }

      if (a.length === 1) {
        return a[0];
      }

      return '';
    }

    if (typeof b === 'string' && typeof a !== 'string') {
      if (b.length >= 2) {
        return b[0] + b[1];
      }

      if (b.length === 1) {
        return b[0];
      }

      return '';
    }
  }

  return '';
};

export default toNameInitials;
