interface Config {
  environment: 'development' | 'staging' | 'production';
  apiUrl: string;
  callServerUrl: string;
  appId: 'tutly' | 'fani';
  appDomain: string;
  signalerUrl: string;
  cookieDomain: string;
}

export const config: Config = {
  environment: process.env.REACT_APP_ENVIRONMENT as 'development' | 'staging' | 'production',
  apiUrl: process.env.REACT_APP_API_URL as string,
  callServerUrl: process.env.REACT_APP_CALL_SERVER_URL as string,
  appId: process.env.REACT_APP_ID as 'tutly' | 'fani',
  appDomain: process.env.REACT_APP_DOMAIN as string,
  signalerUrl: process.env.REACT_APP_SIGNALER_URL as string,
  cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN as string,
};