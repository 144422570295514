import Clock from 'components/atoms/Clock/Clock';
import Timer from 'components/atoms/Timer/Timer';
import MediaSwitch from 'components/molecules/MediaSwitch/MediaSwitch';
import TutorWebinarChatSwitch from 'components/molecules/TutorWebinarChatSwitch/TutorWebinarChatSwitch';
import { HostMediaContext } from 'contexts/hostMediaContext';
import { ITutorWebinar } from 'models/tutor-webinar';
import { Button } from 'primereact/button';
import { PropsWithChildren, useContext } from 'react';
import styles from './TutorWebinarFooter.module.scss';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  conversationId: string | null;
  webinar: ITutorWebinar;
  toggleChat: () => void;
  endCallback: () => void;
}

const TutorWebinarFooter = ({ conversationId, webinar, toggleChat, endCallback }: PropsWithChildren<ComponentProps>) => {
  const { camera, startCamera, stopCamera, microphone, startMicrophone, stopMicrophone, display, startDisplay, stopDisplay } = useContext(HostMediaContext);
  const { t } = useTranslation();

  return (
    <footer className={styles.wrapper}>
      <aside className="h-10 sm:h-5 flex flex-row">
        <div>
          <Clock/>
        </div>
        <div className="w-0.5 bg-white h-full mx-3"/>
        <div className="w-12">
          {<Timer duration={webinar.date.duration}/>}
        </div>
      </aside>
      <nav>
        <MediaSwitch type="camera"
                     isEnabled={!!camera}
                     enable={startCamera}
                     disable={stopCamera}
        />
        <MediaSwitch type="microphone"
                     isEnabled={!!microphone}
                     enable={startMicrophone}
                     disable={stopMicrophone}
        />
        <MediaSwitch type="share"
                     isEnabled={!!display}
                     enable={startDisplay}
                     disable={stopDisplay}
                     enabledColor="danger"
                     disabledColor="primary"
        />
        <Button className="p-button-rounded p-button-danger" onClick={endCallback} tooltip={t("Leave lesson")} tooltipOptions={{ position: 'top' }}>
          <span className="material-icons">call_end</span>
        </Button>
      </nav>
      <TutorWebinarChatSwitch toggleChat={toggleChat} conversationId={conversationId}/>
    </footer>
  );
};

export default TutorWebinarFooter;