import useConversation from 'hooks/useConversation';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  conversationId: string | null;
  toggleChat: () => void;
}

const StudentLessonChatSwitch = ({ conversationId, toggleChat }: PropsWithChildren<ComponentProps>) => {
  const { unreadMessages, refreshUnreadMessages, resetUnreadMessages } = useConversation({ conversationId, userRole: 'student' });

  const { t } = useTranslation();

  const toggleChatHandler = () => {
    toggleChat();
    resetUnreadMessages();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refreshUnreadMessages();
    }, 1500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="relative z-3">
      <Button className="p-button-rounded p-button-icon-only" onClick={toggleChatHandler} tooltip={t("Chat")} tooltipOptions={{ position: 'left' }}>
        <span className="material-icons">chat_bubble_outline</span>
      </Button>
      {!!unreadMessages && <Badge value={unreadMessages} severity="danger" className="absolute top-0 right-0"/>}
    </div>
  );
};

export default StudentLessonChatSwitch;