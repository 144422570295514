import Username from 'components/atoms/Username/Username';
import { config } from 'config';
import useTutorStudent from 'hooks/useTutorStudent';
import { memo, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  studentId: string;
}

const TutorStudentLink = ({ studentId }: PropsWithChildren<ComponentProps>) => {
  const student = useTutorStudent({ studentId });

  const { t } = useTranslation();

  return (
    <a title={t("Show profile")} rel="noreferrer" className="text-white hover:underline" href={`${config.appDomain}/dashboard/tutor/student/${studentId}/details`} target="_blank">
      {
        student && <Username username={student.name}/>
      }
    </a>
  );
};

export default memo(TutorStudentLink, (prevProps, nextProps) => {
  return prevProps.studentId === nextProps.studentId;
});