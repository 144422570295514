import { UserRole } from 'models/user-role';
import { SignalerPeer } from 'signalers/Signaler';
import { Peer as CallPeer } from '@ailogroup/salespoint-call-client/lib/usePeers';
import { useEffect, useState } from 'react';

export interface Peer {
  id: string;
  role: UserRole;
  microphone: MediaStreamTrack | null;
  camera: MediaStreamTrack | null;
  display: MediaStreamTrack | null;
}

const usePeers = (
  signalerPeers: SignalerPeer[] | null,
  callPeers: CallPeer[] | null,
): Peer[] | null => {
  const [ peers, setPeers ] = useState<Peer[] | null>(null);

  useEffect(() => {
    if (null !== signalerPeers) {
      setPeers(
        signalerPeers.map(
          (signalerPeer: SignalerPeer): Peer => {
            let callPeer: CallPeer | null = null;
            if (signalerPeer.callId !== null && callPeers !== null) {
              const matchingPeers = callPeers.filter(
                (callPeer: CallPeer): boolean => callPeer.id === signalerPeer.callId,
              );
              if (matchingPeers.length > 0) {
                callPeer = matchingPeers[0];
              }
            }

            return {
              id: signalerPeer.id,
              role: signalerPeer.role,
              microphone: callPeer !== null ? callPeer.microphone : null,
              camera: callPeer !== null ? callPeer.camera : null,
              display: callPeer !== null ? callPeer.display : null,
            };
          },
        ),
      );
    } else {
      setPeers(null);
    }
  }, [ signalerPeers, callPeers ]);

  return peers;
};

export default usePeers;
