import { Peer } from 'hooks/usePeers';
import { useMemo } from 'react';

const useWebinarGrid = (tutorPeer: Peer | null, studentPeers: Peer[]): { cols: number, rows: number } => {

  const cols = useMemo(() => {
    const peersCount = tutorPeer ? tutorPeer && studentPeers.length === 0 ? 2 : studentPeers.length + 1 : studentPeers.length === 0 ? 2 : studentPeers.length + 1;

    if (peersCount === 1) {
      return 1;
    }

    if (peersCount > 1 && peersCount < 5) {
      return 2;
    }

    if (peersCount > 4 && peersCount < 10) {
      return 3;
    }

    if (peersCount > 9 && peersCount < 17) {
      return 4;
    }

    if (peersCount > 16 && peersCount < 26) {
      return 5;
    }

    if (peersCount > 25 && peersCount < 37) {
      return 6;
    }

    if (peersCount > 36 && peersCount < 50) {
      return 7;
    }

    if (peersCount > 49 && peersCount < 65) {
      return 8;
    }

    if (peersCount > 64 && peersCount < 82) {
      return 9;
    }

    if (peersCount > 81 && peersCount < 101) {
      return 10;
    }

    if (peersCount > 100 && peersCount < 122) {
      return 11;
    }

    if (peersCount > 121 && peersCount < 145) {
      return 12;
    }

    if (peersCount > 144 && peersCount < 170) {
      return 13;
    }

    if (peersCount > 169 && peersCount < 197) {
      return 14;
    }

    if (peersCount > 196 && peersCount < 226) {
      return 15;
    }

    return 16;
  }, [ tutorPeer, studentPeers ]);

  const rows = useMemo(() => {
    const peersCount = tutorPeer ? tutorPeer && studentPeers.length === 0 ? 2 : studentPeers.length + 1 : studentPeers.length === 0 ? 2 : studentPeers.length + 1;

    if (peersCount === 1) {
      return 1;
    }

    if (peersCount > 1 && peersCount < 5) {
      return 2;
    }

    if (peersCount > 4 && peersCount < 10) {
      return 3;
    }

    if (peersCount > 9 && peersCount < 17) {
      return 4;
    }

    if (peersCount > 16 && peersCount < 26) {
      return 5;
    }

    if (peersCount > 25 && peersCount < 37) {
      return 6;
    }

    if (peersCount > 36 && peersCount < 50) {
      return 7;
    }

    if (peersCount > 49 && peersCount < 65) {
      return 8;
    }

    if (peersCount > 64 && peersCount < 82) {
      return 9;
    }

    if (peersCount > 81 && peersCount < 101) {
      return 10;
    }

    if (peersCount > 100 && peersCount < 122) {
      return 11;
    }

    if (peersCount > 121 && peersCount < 145) {
      return 12;
    }

    if (peersCount > 144 && peersCount < 170) {
      return 13;
    }

    if (peersCount > 169 && peersCount < 197) {
      return 14;
    }

    if (peersCount > 196 && peersCount < 226) {
      return 15;
    }

    return 16;
  }, [ tutorPeer, studentPeers ]);

  return {
    cols,
    rows,
  };
};

export default useWebinarGrid;
