import { useCall } from '@ailogroup/salespoint-call-client';
import LoadingSpinner from 'components/molecules/LoadingSpinner/LoadingSpinner';
import MediaErrorToast from 'components/molecules/MediaErrorToast/MediaErrorToast';
import SignalerConflictDialog from 'components/molecules/SignalerConflictDialog/SignalerConflictDialog';
import SignalerErrorDialog from 'components/molecules/SignalerErrorDialog/SignalerErrorDialog';
import StudentWebinarFooter from 'components/molecules/StudentWebinarFooter/StudentWebinarFooter';
import StudentWebinarMedia from 'components/molecules/StudentWebinarMedia/StudentWebinarMedia';
import Chat from 'components/organisms/Chat/Chat';
import { config } from 'config';
import { HostMediaContext } from 'contexts/hostMediaContext';
import useCallUpstream from 'hooks/useCallUpstream';
import useChat from 'hooks/useChat';
import usePeers from 'hooks/usePeers';
import usePinnedPeer from 'hooks/usePinnedPeer';
import useSignaler, { SignalerState } from 'hooks/useSignaler';
import useSignalerPeers from 'hooks/useSignalerPeers';
import useSoundOnPeerChanges from 'hooks/useSoundOnPeerChanges';
import useStudentPeers from 'hooks/useStudentPeers';
import useStudentWebinarConversationId from 'hooks/useStudentWebinarConversationId';
import useStudentWebinarStudents from 'hooks/useStudentWebinarStudents';
import useTutorPeer from 'hooks/useTutorPeer';
import { IStudentWebinar } from 'models/student-webinar';
import { IUser } from 'models/user';
import { PropsWithChildren, useContext, useMemo } from 'react';
import styles from './StudentWebinarRoom.module.scss';

interface ComponentProps {
  user: IUser;
  webinar: IStudentWebinar;
  enterKey: string;
  endCallback: () => void;
}

const StudentWebinarRoom = ({ user, webinar, enterKey, endCallback }: PropsWithChildren<ComponentProps>) => {
  const { isChatOpened, toggleChat } = useChat();
  const { microphone, camera, display, errorMicrophone, errorCamera } = useContext(HostMediaContext);
  const { state: signalerState, signaler } = useSignaler(webinar.id, 'student', enterKey);
  const signalerPeers = useSignalerPeers(signaler);

  const callEnabled = useMemo<boolean>(() => signalerState === SignalerState.CONNECTED, [ signalerState ]);
  const { peerId: studentCallId, peers: callPeers } = useCall({
    enabled: callEnabled,
    serverUrl: config.callServerUrl,
    roomId: webinar.id,
    microphone,
    camera,
    display,
    consumePreferences: null,
  });

  const conversationId = useStudentWebinarConversationId({ webinarId: webinar.id });
  const students = useStudentWebinarStudents({ webinarId: webinar.id });

  useCallUpstream(signaler, studentCallId);

  const peers = usePeers(signalerPeers, callPeers);
  const tutorPeer = useTutorPeer(peers);
  const studentPeers = useStudentPeers(peers);

  useSoundOnPeerChanges(peers);

  const { pinnedPeerId, togglePinnedPeer } = usePinnedPeer();

  switch (signalerState) {
    case SignalerState.CONFLICT: {
      return <SignalerConflictDialog/>;
    }
    case SignalerState.ERROR: {
      return <SignalerErrorDialog/>;
    }
    case SignalerState.CONNECTING: {
      return <LoadingSpinner/>;
    }
    case SignalerState.CONNECTED: {
      return (
        <>
          <section className={styles.wrapper}>
            <div className={styles.streams}>
              <header className="h-6"/>
              <StudentWebinarMedia user={user} tutorPeer={tutorPeer} studentPeers={studentPeers} togglePinnedPeer={togglePinnedPeer} pinnedPeerId={pinnedPeerId} webinar={webinar} students={students}/>
              <StudentWebinarFooter webinar={webinar} toggleChat={toggleChat} endCallback={endCallback} conversationId={conversationId}/>
            </div>

            {
              isChatOpened && conversationId && (
                <aside className={styles.chat} data-pinned={!!pinnedPeerId}>
                  <Chat conversationId={conversationId} userRole="student"/>
                </aside>
              )
            }
          </section>

          <MediaErrorToast context="microphone" error={errorMicrophone}/>
          <MediaErrorToast context="camera" error={errorCamera}/>
        </>
      );
    }
  }
};

export default StudentWebinarRoom;