import EventEmitter from 'events';
import { UserRole } from 'models/user-role';
import { Socket } from 'socket.io-client';

export class PeersEvent {
  public static readonly NAME = 'peers';

  constructor(private readonly peers: SignalerPeer[]) {
  }

  getPeers(): SignalerPeer[] {
    return this.peers;
  }
}

export interface SignalerPeer {
  id: string;
  role: UserRole;
  callId: string | null;
}

declare interface Signaler {
  on(event: 'peers', listener: (event: PeersEvent) => void): this;
}

class Signaler extends EventEmitter {
  constructor(private readonly socket: Socket) {
    super();

    this.socket.on('peers', this.handlePeers.bind(this));
  }

  handlePeers(peers: SignalerPeer[]): void {
    this.emit(PeersEvent.NAME, new PeersEvent(peers));
  }

  askForPeers(): void {
    this.socket.emit('peers?');
  }

  callIn(callId: string): void {
    this.socket.emit('callIn', { callId });
  }

  hungUp(): void {
    this.socket.emit('hungUp');
  }
}

export default Signaler;
