import { useEffect, useState } from 'react';
import Signaler, { SignalerPeer, PeersEvent } from 'signalers/Signaler';

const useSignalerPeers = (
  signaler: Signaler | null,
): SignalerPeer[] | null => {
  const [ peers, setPeers ] = useState<SignalerPeer[] | null>(null);

  useEffect(() => {
    if (signaler !== null) {
      const handlePeers = (event: PeersEvent) => {
        setPeers(event.getPeers());
      };
      signaler.on('peers', handlePeers);

      signaler.askForPeers();

      return () => {
        signaler.off('peers', handlePeers);
      };
    } else {
      setPeers(null);
    }
  }, [ signaler ]);

  return peers;
};

export default useSignalerPeers;
