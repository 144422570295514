import LoadingSpinner from 'components/molecules/LoadingSpinner/LoadingSpinner';
import UnauthorizedSpinner from 'components/molecules/UnauthorizedSpinner/UnauthorizedSpinner';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { IUser } from 'models/user';
import { useParams } from 'react-router';
import redirectToAuth from 'utilities/redirectToAuth';
import useWebinarClassroom, { WebinarClassroomStatus } from 'hooks/useWebinarClassroom';
import WebinarErrorMessage from 'components/molecules/WebinarErrorMessage/WebinarErrorMessage';
import StudentWebinarRoom from 'components/organisms/StudentWebinarRoom/StudentWebinarRoom';
import StudentWebinarLobby from 'components/organisms/StudentWebinarLobby/StudentWebinarLobby';
import useStudentWebinar from 'hooks/useStudentWebinar';
import StudentWebinarEnd from 'components/organisms/StudentWebinarEnd/StudentWebinarEnd';
import useAuthToken from 'hooks/useAuthToken';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  user: IUser;
}

const StudentWebinar = ({ user }: PropsWithChildren<ComponentProps>) => {
  const [ joined, setJoined ] = useState<boolean>(false);
  const join = useCallback(() => {
    setJoined(true);
  }, []);

  const [ ended, setEnded ] = useState<boolean>(false);
  const end = useCallback(() => {
    setJoined(false);
    setEnded(true);
  }, []);

  const reset = useCallback(() => {
    setJoined(false);
    setEnded(false);
  }, []);

  const params = useParams<{ webinarId: string }>();
  const webinarId = useMemo(() => {
    if (params.webinarId !== undefined) {
      return params.webinarId;
    } else {
      throw new Error('webinarId parameter does not exist');
    }
  }, [ params ]);
  const { status, enterKey } = useWebinarClassroom(webinarId, 'student');
  const webinar = useStudentWebinar(webinarId);
  const { removeAuthToken } = useAuthToken();

  const { t } = useTranslation();

  useEffect(() => {
    if (status === WebinarClassroomStatus.UNAUTHENTICATED) {
      removeAuthToken();
      redirectToAuth();
    }
  }, [ status ]);

  if (ended) {
    return <StudentWebinarEnd joinAgainCallback={reset}/>;
  } else {
    switch (status) {
      case WebinarClassroomStatus.LOADING:
        return (<LoadingSpinner/>);
      case WebinarClassroomStatus.UNAUTHENTICATED:
      case WebinarClassroomStatus.UNAUTHORIZED:
        return <UnauthorizedSpinner/>;
      case WebinarClassroomStatus.WEBINAR_NOT_FOUND:
        return (
          <div className="flex flex-col h-full min-h-screen justify-center items-center py-6">
            <WebinarErrorMessage role="student">{t("Webinar not found")}</WebinarErrorMessage>
          </div>
        );
      case WebinarClassroomStatus.WEBINAR_CANCELLED:
        return (
          <div className="flex flex-col h-full min-h-screen justify-center items-center py-6">
            <WebinarErrorMessage role="student">{t("Webinar has been canceled")}</WebinarErrorMessage>
          </div>
        );
      case WebinarClassroomStatus.READY:
        if (webinar === null || enterKey === null) {
          return (<LoadingSpinner/>);
        } else if (joined) {
          return <StudentWebinarRoom user={user} webinar={webinar} enterKey={enterKey} endCallback={end}/>;
        } else {
          return <StudentWebinarLobby user={user} webinar={webinar} joinCallback={join}/>;
        }
    }
  }
};

export default StudentWebinar;