export enum BackendErrorType {
  VALIDATION_FAILED = 'validationFailed',
  INVALID_GOOGLE_TOKEN = 'invalidGoogleToken',
  GOOGLE_USER_NOT_REGISTERED = 'googleUserNotRegistered',
  GOOGLE_USER_ALREADY_REGISTERED = 'googleUserAlreadyRegistered',
  AUTHENTICATION_REQUIRED = 'authenticationRequired',
  AUTHENTICATION_ERROR = 'authenticationError',
  INVALID_IMAGE_TYPE = 'invalidImageType',
  USER_IS_ALREADY_A_TUTOR = 'userIsAlreadyATutor',
  USER_IS_NOT_A_TUTOR = 'userIsNotATutor',
  MERCHANT_NOT_REGISTERED = 'merchantNotRegistered',
  TUTOR_NOT_FOUND = 'tutorNotFound',
  STUDENT_NOT_ENROLLED = 'studentNotEnrolled',
  TUTOR_LESSON_NOT_FOUND = 'tutorLessonNotFound',
  LESSON_INACTIVE = 'lessonInactive',
  UNABLE_TO_CANCEL_TUTOR_LESSON = 'unableToCancelTutorLesson',
  TUTOR_ACCOUNT_ALREADY_EXISTS = 'tutorAccountAlreadyExists',
  UNSUPPORTED_TUTOR_ACCOUNT_COUNTRY = 'unsupportedTutorAccountCountry',
  TUTOR_ACCOUNT_NOT_FOUND = 'tutorAccountNotFound',
  INVALID_CURSOR = 'invalidCursor',
  SUBSCRIPTION_ALREADY_EXISTS = 'subscriptionAlreadyExists',
  PLAN_NOT_FOUND = 'planNotFound',
  SUBSCRIPTION_NOT_FOUND = 'subscriptionNotFound',
  ACTIVE_SUBSCRIPTION = 'activeSubscription',
  INACTIVE_SUBSCRIPTION = 'inactiveSubscription',
  SUBSCRIPTION_CANCELLATION_ALREADY_SCHEDULED = 'subscriptionCancellationAlreadyScheduled',
  SUBSCRIPTION_CANCELLATION_NOT_SCHEDULED = 'subscriptionCancellationNotScheduled',
  SUBSCRIPTION_PAYMENT_ERROR = 'subscriptionPaymentError',
  TUTOR_UNAVAILABLE = 'tutorUnavailable',
  WEBINAR_NOT_FOUND = 'webinarNotFound',
  WEBINAR_CANCELLED = 'webinarCancelled',
  WEBINAR_ALREADY_STARTED = 'webinarAlreadyStarted',
  WEBINAR_STUDENT_LIMIT_EXCEEDED = 'webinarStudentLimitExceeded',
  STUDENT_NOT_ENROLLED_FOR_WEBINAR = 'studentNotEnrolledForWebinar',
  USER_IS_ALREADY_A_STUDENT = 'userIsAlreadyAStudent',
  USER_IS_NOT_A_STUDENT = 'userIsNotAStudent',
  STUDENT_ALREADY_ENROLLED = 'studentAlreadyEnrolled',
  TUTOR_PACKAGE_NOT_FOUND = 'tutorPackageNotFound',
  STUDENT_UNAVAILABLE = 'studentUnavailable',
  TUTOR_TIME_EXHAUSTED = 'tutorTimeExhausted',
  STUDENT_LESSON_NOT_FOUND = 'studentLessonNotFound',
  UNABLE_TO_CANCEL_STUDENT_LESSON = 'unableToCancelStudentLesson',
  UNABLE_TO_CONFIRM_STUDENT_LESSON = 'unableToConfirmStudentLesson',
  WEBINAR_SEAT_NOT_AVAILABLE = 'webinarSeatNotAvailable',
  STUDENT_ALREADY_ENROLLED_FOR_WEBINAR = 'studentAlreadyEnrolledForWebinar',
  CONVERSATION_NOT_FOUND = 'conversationNotFound',
  PEER_DOES_NOT_PARTICIPATE_IN_CONVERSATION = 'peerDoesNotParticipateInConversation',
  STUDENT_PEER_NOT_FOUND = 'studentPeerNotFound',
  FILE_NOT_FOUND = 'fileNotFound',
  EMPTY_MESSAGE = 'emptyMessage',
  INTEGRATION_NOT_FOUND = 'integrationNotFound',
  INVALID_AUTHORIZATION_CODE = 'invalidAuthorizationCode',
  INTEGRATION_ALREADY_EXISTS = 'integrationAlreadyExists',
  INTEGRATION_NOT_AUTHORIZED = 'integrationNotAuthorized',
  CALENDAR_NOT_FOUND = 'calendarNotFound',
  CALENDAR_NOT_WRITABLE = 'calendarNotWritable',
  INTEGRATION_REMINDER_NOT_FOUND = 'integrationReminderNotFound',
  LESSON_NOT_STARTED = 'lessonNotStarted',
  LESSON_FINISHED = 'lessonFinished',
  LESSON_CANCELLED = 'lessonCancelled',
  TUTOR_LESSON_TIME_UNAVAILABLE = 'tutorLessonTimeUnavailable',
}
