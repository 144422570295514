import { Peer } from 'hooks/usePeers';
import usePreviousValue from 'hooks/usePreviousValue';
import { useEffect } from 'react';

const useSoundOnPeerChanges = (peers: Peer[] | null): void => {
  const peersCount = usePreviousValue<number>(peers?.length ?? 0);

  useEffect(() => {
    if (peers && peersCount !== undefined) {
      if (peers.length > peersCount) {
        const audio = new Audio(`${process.env.PUBLIC_URL}/peer-connected.wav`);
        audio.play();
      }

      if (peers.length < peersCount) {
        const audio = new Audio(`${process.env.PUBLIC_URL}/peer-disconnected.wav`);
        audio.play();
      }
    }
  }, [ peers ]);
};

export default useSoundOnPeerChanges;
