import useApi from 'hooks/useApi';
import useAuthToken from 'hooks/useAuthToken';
import { IStudentLesson } from 'models/student-lesson';
import { ITutorLesson } from 'models/tutor-lesson';
import { IUser } from 'models/user';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { Skeleton } from 'primereact/skeleton';
import { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import redirectToAuth from 'utilities/redirectToAuth';
import toNameInitials from 'utilities/toNameInitials';
import { AxiosError } from 'axios';
import { BackendError } from 'models/backend-error';

interface ComponentProps {
  user: IUser;
  lesson: IStudentLesson | ITutorLesson;
}

const MembersAvatar = ({ user, lesson }: PropsWithChildren<ComponentProps>) => {
  const [ hostAvatarLoading, setHostAvatarLoading ] = useState<boolean>(true);
  const [ peerAvatarLoading, setPeerAvatarLoading ] = useState<boolean>(true);

  const [ hostAvatar, setHostAvatar ] = useState<string | undefined>();
  const [ peerAvatar, setPeerAvatar ] = useState<string | undefined>();

  const [ hostLabel, setHostLabel ] = useState<string | undefined>();
  const [ peerLabel, setPeerLabel ] = useState<string | undefined>();

  const api = useApi();

  const { getAuthToken, removeAuthToken } = useAuthToken();
  const authToken = useMemo<string | null>(() => getAuthToken() ?? null, [ getAuthToken ]);

  useEffect(() => {
    if (authToken) {
      api.request<Blob>({
        method: 'GET',
        url: `users/current/picture`,
        responseType: 'blob',
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }).then(({ data }) => {
        if (data.size) {
          return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);

            reader.onloadend = () => {
              resolve(reader.result as string);
            };
          });
        } else {
          return Promise.resolve(null);
        }
      }).then((avatar) => {
        setHostAvatarLoading(false);

        if (avatar) {
          setHostAvatar(avatar);
        }
      }).catch((error: AxiosError<BackendError>) => {
        setHostAvatarLoading(false);

        if (error.response?.status === 401) {
          removeAuthToken();
          redirectToAuth();
        }
      });
    } else {
      redirectToAuth();
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      if ('tutor' in lesson) {
        api.request<Blob>({
          method: 'GET',
          url: `student/tutors/${lesson.tutor.id}/picture`,
          responseType: 'blob',
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }).then(({ data }) => {
          if (data.size) {
            return new Promise<string>((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(data);

              reader.onloadend = () => {
                resolve(reader.result as string);
              };
            });
          } else {
            return Promise.resolve(null);
          }
        }).then((avatar) => {
          setPeerAvatarLoading(false);

          if (avatar) {
            setPeerAvatar(avatar);
          }
        }).catch((error: AxiosError<BackendError>) => {
          setPeerAvatarLoading(false);

          if (error.response?.status === 401) {
            removeAuthToken();
            redirectToAuth();
          }
        });
      }

      if ('student' in lesson) {
        api.request<Blob>({
          method: 'GET',
          url: `tutor/students/${lesson.student.id}/picture`,
          responseType: 'blob',
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }).then(({ data }) => {
          if (data.size) {
            return new Promise<string>((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(data);

              reader.onloadend = () => {
                resolve(reader.result as string);
              };
            });
          } else {
            return Promise.resolve(null);
          }
        }).then((avatar) => {
          setPeerAvatarLoading(false);

          if (avatar) {
            setPeerAvatar(avatar);
          }
        }).catch((error: AxiosError<BackendError>) => {
          setPeerAvatarLoading(false);

          if (error.response?.status === 401) {
            removeAuthToken();
            redirectToAuth();
          }
        });
      }
    } else {
      redirectToAuth();
    }
  }, [ authToken, lesson ]);

  useEffect(() => {
    setHostLabel(toNameInitials(user.name));
  }, [ user ]);

  useEffect(() => {
    if ('tutor' in lesson) {
      setPeerLabel(toNameInitials(lesson.tutor.name));
    }

    if ('student' in lesson) {
      setPeerLabel(toNameInitials(lesson.student.name));
    }
  }, [ lesson ]);

  if (hostAvatarLoading || peerAvatarLoading) {
    return (
      <Skeleton shape="rectangle" width="48px" height="32px"/>
    );
  } else if (hostAvatar && peerAvatar) {
    return (
      <AvatarGroup>
        <Avatar image={hostAvatar} shape="circle"/>
        <Avatar image={peerAvatar} shape="circle"/>
      </AvatarGroup>
    );
  } else if (hostAvatar && !peerAvatar) {
    return (
      <AvatarGroup>
        <Avatar image={hostAvatar} shape="circle"/>
        <Avatar icon="pi pi-user" label={peerLabel} shape="circle"/>
      </AvatarGroup>
    );
  } else if (!hostAvatar && peerAvatar) {
    return (
      <AvatarGroup>
        <Avatar icon="pi pi-user" label={hostLabel} shape="circle"/>
        <Avatar image={peerAvatar} shape="circle"/>
      </AvatarGroup>
    );
  } else {
    return (
      <AvatarGroup>
        <Avatar icon="pi pi-user" label={hostLabel} shape="circle"/>
        <Avatar icon="pi pi-user" label={peerLabel} shape="circle"/>
      </AvatarGroup>
    );
  }
};

export default memo(MembersAvatar, (prevProps, nextProps) => {
  return prevProps.user.id === nextProps.user.id && prevProps.lesson.id === nextProps.lesson.id;
});