import { config } from 'config';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const SignalerErrorDialog = () => {
  const { t } = useTranslation();

  const reloadHandler = useCallback(() => {
    window.location.reload();
  }, []);

  const backHandler = useCallback(() => {
    window.open(config.appDomain, '_self');
  }, []);

  return (
    <Dialog header={t("Connection closed")} visible={true} closable={false} onHide={() => {}}>
      <div className="py-2">
        <Button className="w-full" icon="pi pi-refresh" label={t("Reload page")} onClick={reloadHandler}/>
        <Button className="w-full mt-2 p-button-outlined" icon="pi pi-th-large" label={t("Back to dashboard")} onClick={backHandler}/>
      </div>
    </Dialog>
  );
};

export default SignalerErrorDialog;