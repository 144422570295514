import useCookie from './useCookie';

const useAuthToken = () => {
  const { getCookie, setCookie, removeCookie, hasCookie } = useCookie();
  const maxInt32 = '2147483647';

  const getAuthToken = (): string | null | undefined => {
    return getCookie('token');
  };

  const setAuthToken = (token: string): void => {
    setCookie('token', token, [
      [ 'max-age', maxInt32 ],
    ]);
  };

  const removeAuthToken = (): void => {
    return removeCookie('token');
  };

  const hasAuthToken = (): boolean => {
    return hasCookie('token');
  };

  return {
    getAuthToken,
    setAuthToken,
    removeAuthToken,
    hasAuthToken,
  };
};

export default useAuthToken;
